export const addressFields = [
    "logradouroS1",
    "numeroS1",
    "complementoS1",
    "bairroS1",
    "estadoS1",
    "cidadeS1",
    "cidadeMap",
    "cepS1",
    "logradouroS2",
    "numeroS2",
    "complementoS2",
    "bairroS2",
    "estadoS2",
    "cidadeS2",
    "cepS2",
    "logradouroO1",
    "numeroO1",
    "complementoO1",
    "bairroO1",
    "estadoO1",
    "cidadeO1",
    "cepO1",
    "logradouroO2",
    "numeroO2",
    "complementoO2",
    "bairroO2",
    "estadoO2",
    "cidadeO2",
    "cepO2",
]
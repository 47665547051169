export const reportResultOptions =  [
    "Contrato fechado",
    "Contrato fechado por indicação",
    "Endereço não encontrado",
    "Litispendência",
    "Já tem advogado",
    "Retornar",
    "Não tem direito",
    "Não tem interesse",
    "Localidade diversa",
]
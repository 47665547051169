import React from 'react';

import { CardContent } from './styles';

function CustomCardContent({ color, value, description, Avatar }) {
    return (
        <CardContent color={color}>
            <h1 className="value">{value}</h1>
            <h1 className="description">{description}</h1>
            {Avatar}
        </CardContent>
    )
}

export default CustomCardContent;
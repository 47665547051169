import React, { useContext } from "react";
import { Navigate } from 'react-router-dom';
import { AuthContext } from "../contexts/AuthContext";
import { ContainerLoading } from "../contexts/styles";
import DotLoader from "react-spinners/ClipLoader";

export const AdminRoute = ({ children, redirectTo }) => {
    const { isAdmin, isManager, loading, user } = useContext(AuthContext); 

    if(!user || loading){
        return <ContainerLoading>
            <DotLoader
                size={80}
                color="#DE6161"
                loading={true}
                speedMultiplier={1.5}
            />
        </ContainerLoading>
    }

    return user && (isAdmin || isManager) ? (
        children
    ) : (
        <Navigate to={redirectTo} replace/>
    )
}
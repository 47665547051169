import { useContext, useEffect } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { mappers } from "../helpers/mappers";

import { collection, query, onSnapshot, where, orderBy } from "firebase/firestore";
import db from '../firebase/config';
import { arrayHelper } from "../helpers/arrayHelper";
import { UsersContext } from "../contexts/UsersContext";
import { alertSuccessStyle } from "../resources/alertSuccessStyle";
import { toast } from "react-toastify";
import { ClientsContext } from "../contexts/ClientsContext";
import { ReportsContext } from "../contexts/ReportsContext";

const date = new Date();

let numberContracts = 0;

export default function useAttendClients(){
    const { user, isAdmin, isManager } = useContext(AuthContext);

    const { users } = useContext(UsersContext);
    const { attendClients, setAttendClients } = useContext(ClientsContext);
    const { reports, setReports, reportsUser, setReportsUser } = useContext(ReportsContext);

    const getAttendClientsAdmin = async () => {
        const consulta = query(collection(db, 'reports'), orderBy("time", "desc"));
        onSnapshot(consulta, (querySnapshot) => {
            const mappedDocs = mappers.mapFirebaseDoc(querySnapshot)

            const mappedAttendClients = mappers.mapAttendClients(mappedDocs)

            setAttendClients(arrayHelper.ordenateArrayDesc(mappedAttendClients, 'time'))
            setReports(mappedDocs)
        }, error => console.log("getAttendClientsAdmin error", error));
    }

    const getAttendClientsManager = async (operatorsArray) => {
        if(operatorsArray.length == 0){
            setAttendClients([])
            setReports([])
            return
        }

        const operatorsUid = arrayHelper.reduceToSimple(operatorsArray, 'uid');

        const consulta = query(collection(db, 'reports'), where("operatorUid", "in", operatorsUid), 
            orderBy("time", "desc"));
        onSnapshot(consulta, (querySnapshot) => {
            const mappedDocs = mappers.mapFirebaseDoc(querySnapshot)

            const mappedAttendClients = mappers.mapAttendClients(mappedDocs)

            setAttendClients(arrayHelper.ordenateArrayDesc(mappedAttendClients, 'time'))
            setReports(mappedDocs)
        }, error => {
            console.log("getAttendClientsManager error: ", error)
        });
    }

    const getReportsUser = async (user) => {
        const consulta = query(collection(db, 'reports'), 
            where('operatorUid', '==', user.uid), where('result', '==', 'Retornar'));
        await onSnapshot(consulta, (querySnapshot) => {
            const mappedDocs = mappers.mapFirebaseDoc(querySnapshot)
            
            setReportsUser(arrayHelper.ordenateArrayDesc(mappedDocs, 'time'))
        }, error => {
            console.log("getReportsUser error: ", error)
        });
    }

     //Notificações web de novos contratos
     const checkNewContracts = async () => {
        const timeToCompare = new Date(`${date.getMonth()+1}-1-${date.getFullYear()}`)
        const consulta = query(collection(db, 'reports'), 
            where("result", "in", ["Contrato fechado", "Contrato fechado por indicação"]));
        await onSnapshot(consulta, (querySnapshot) => {
            const database = [];
            
            const data = querySnapshot.docs;
            
            for(const i in data){
                database.push(data[i].data());
            }
            
            if(numberContracts != 0 && database.length > numberContracts){
                toast("Novo contrato fechado", alertSuccessStyle);
            }

            numberContracts = database.length
        });
    }

    useEffect(() => {
        if(isAdmin){
            if(!attendClients){
                getAttendClientsAdmin();
                checkNewContracts();
            }
        }
    
        if(isManager){
            if(users && !attendClients){
                getAttendClientsManager(users.operators);
                checkNewContracts();
            }
        }
    
        if(!isAdmin && !isManager){
            if(!reportsUser && user){
                getReportsUser(user);
            }
        }
    }, [users])

    return {
        attendClients: attendClients,
        reports: reports,
        reportsUser: reportsUser,
        loadingAttendClients: !attendClients || !reports || !reportsUser ? true : false
    }
}
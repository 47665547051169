import React, { useState, useEffect } from 'react';

import { 
    Container,
    FieldText,
    TextInput,
    ActionButtons,
    CancelButton,
    SaveButton
} from './styles';

//Custom components
import ModalHeader from '../ModalHeader';
import CustomLoading from '../CustomLoading';
import CustomDivider from '../CustomDivider';

//Util
import Select from 'react-select';

function ModalFormMap({ title, data, loading, optionsCities, onClose, onSave }) {

    const [ newMap, setNewMap ] = useState('');

    useEffect(() => {
        setNewMap(data)
    }, [])

    const handleInputChange = (event) => {
        setNewMap({
            ...newMap,
            mapName: event.target.value,
        })
    }

    const handleInputCities = (items) => {
        setNewMap({
            ...newMap,
            cities: items,
        });
    }

    const customStyle = {      
        container: (base) => ({
            ...base,
            borderRadius: 40
        }),
        control: (base) => ({
            ...base,
            padding: 10,
            borderRadius: 40            
        }),
        multiValue: (base) => ({
            ...base,
            borderRadius: 40,
            backgroundColor: '#ECF2FB'
        }),
    } 

    if(loading || !newMap){
        <Container>
            <CustomLoading />
        </Container>
    }
    
    return (
        <Container>
            <ModalHeader 
                title={title}
                onClose={onClose}
            />

            <FieldText>
                Nome
            </FieldText>
            <TextInput>
                <input 
                    name="mapName" 
                    onChange={event => handleInputChange(event)}
                    placeholder='Nome do mapa'
                    defaultValue={newMap ? newMap.mapName : null}
                />
            </TextInput>
            <br />
            <FieldText>
                Cidades
            </FieldText>
            <Select 
                isClearable={false} 
                value={newMap ? newMap.cities : null}
                isSearchable={true} 
                closeMenuOnSelect={false} 
                options={optionsCities} 
                isMulti={true} 
                styles={customStyle}
                autoFocus={true}
                openMenuOnFocus={true}
                onChange={(item) => {
                    handleInputCities(item)
                }}
            />
            <br />
            <CustomDivider />
            <ActionButtons>
                {loading
                    ? <CustomLoading />
                    : <>
                        <CancelButton onClick={() => {
                            onClose()
                        }}>
                            <p>Cancelar</p>
                        </CancelButton>
                        <SaveButton onClick={async () => {
                            await onSave(newMap)                               
                        }}>
                            <p>Salvar</p>
                        </SaveButton>
                    </>
                }
             </ActionButtons> 
        </Container>
    )
}

export default ModalFormMap;
import React, { useEffect, useState, createContext } from "react";

import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { addUserLogs } from "../firebase/logs/users/addUserLogs";
import { toast } from "react-toastify";
import { alertSuccessStyle } from "../resources/alertSuccessStyle";
import { alertErrorStyle } from "../resources/alertErrorStyle";
import { arrayHelper } from "../helpers/arrayHelper";
import { doc, updateDoc } from "firebase/firestore";
import db from '../firebase/config';

export const UsersContext = createContext();

export const UsersProvider = (props) => {
    const { children } = props;

    const auth = getAuth();
    
    const [ users, setUsers ] = useState('');

    const clearUsersContext = async () => {
        setUsers('');
        return
    }

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const profile = (await user.getIdTokenResult()).claims;

                getUsersAdmin(user, profile)
            }
        });  
    }, [])
    
    //Get operadores
    const getUsersAdmin = async (user) => {
        await user.getIdToken(/* forceRefresh */ true).then(async function(idToken){
            const functions = getFunctions();
            const getUsers = httpsCallable(functions, 'getUsers');
            await getUsers({ idToken: idToken }).then(async result => {
                const data = result.data;


                if(data.status === 'error'){
                    return alert(`Ocorreu um erro: ${data.code}`);
                }
                
                if(data.status === 'success'){
                    setUsers({
                        allUsers: data.allUsers,
                        admins: data.admins,
                        managers: data.managers,
                        operators: data.operators,
                        rootUid: data.rootUid
                    })
                }
            })
        })
        .catch((error) => {
            console.log("getUsersAdmin error: ", error)
        })
    }

    const updateRoleToAdmin = async (selectedUser, oldRole) => {
        const newRoleLabel = 'Admin'
        const uid = selectedUser.uid;

        await auth.currentUser.getIdToken(/* forceRefresh */ true).then(async function(idToken) {
            const functions = getFunctions();
            const setAdmin = httpsCallable(functions, 'setAdmin');
            return await setAdmin({idToken: idToken, uid: uid, oldRole: oldRole }).then(async result => {
                const data = result.data;

                if(data.success){
                    const logMessage = `${selectedUser.userName} foi definido como ${newRoleLabel}`

                    await addUserLogs(uid, logMessage)
                    toast(logMessage, alertSuccessStyle)

                    return true
                }else{
                    if(data.error === 'Denied'){
                        const logMessage = `Acesso negado para definir ${selectedUser.userName} como ${newRoleLabel}`
    
                        await addUserLogs(uid, logMessage)
                        toast(logMessage, alertErrorStyle)
    
                        return false
                    }else{
                        const logMessage = `Erro ao definir ${selectedUser.userName} como ${newRoleLabel}: ${data.error}`

                        await addUserLogs(uid, logMessage)
                        toast(logMessage, alertErrorStyle)

                        return false
                    }
                }
            })
        }).catch(async (error) => {
            const logMessage = `Erro ao definir ${selectedUser.userName} como ${newRoleLabel}: ${error.code}`

            await addUserLogs(uid, logMessage)
            toast(logMessage, alertErrorStyle)

            return false
        }); 
    }

    const updateRoleToManager = async (selectedUser, oldRole) => {
        const newRoleLabel = 'Gerente'
        const uid = selectedUser.uid;

        await auth.currentUser.getIdToken(/* forceRefresh */ true).then(async function(idToken) {
            const functions = getFunctions();
            const setManager = httpsCallable(functions, 'setManager');
            return await setManager({idToken: idToken, uid: uid, oldRole: oldRole }).then(async result => {
                const data = result.data;

                if(data.success){
                    const logMessage = `${selectedUser.userName} foi definido como ${newRoleLabel}`

                    await addUserLogs(uid, logMessage)
                    toast(logMessage, alertSuccessStyle)

                    return true
                }else{
                    if(data.error === 'Denied'){
                        const logMessage = `Acesso negado para definir ${selectedUser.userName} como ${newRoleLabel}`
    
                        await addUserLogs(uid, logMessage)
                        toast(logMessage, alertErrorStyle)
    
                        return false
                    }else{
                        const logMessage = `Erro ao definir ${selectedUser.userName} como ${newRoleLabel}: ${data.error}`

                        await addUserLogs(uid, logMessage)
                        toast(logMessage, alertErrorStyle)

                        return false
                    }
                }
            })
        }).catch(async (error) => {
            const logMessage = `Erro ao definir ${selectedUser.userName} como ${newRoleLabel}: ${error.code}`

            await addUserLogs(uid, logMessage)
            toast(logMessage, alertErrorStyle)

            return false
        }); 
    }

    const updateRoleToOperator = async (selectedUser, oldRole) => {
        const newRoleLabel = 'Operador'
        const uid = selectedUser.uid;

        await auth.currentUser.getIdToken(/* forceRefresh */ true).then(async function(idToken) {
            const functions = getFunctions();
            const setOperator = httpsCallable(functions, 'setOperator');
            return await setOperator({idToken: idToken, uid: uid, oldRole: oldRole }).then(async result => {
                const data = result.data;

                if(data.success){
                    const logMessage = `${selectedUser.userName} foi definido como ${newRoleLabel}`

                    await addUserLogs(uid, logMessage)
                    toast(logMessage, alertSuccessStyle)

                    return true
                }else{
                    if(data.error === 'Denied'){
                        const logMessage = `Acesso negado para definir ${selectedUser.userName} como ${newRoleLabel}`
    
                        await addUserLogs(uid, logMessage)
                        toast(logMessage, alertErrorStyle)
    
                        return false
                    }else{
                        const logMessage = `Erro ao definir ${selectedUser.userName} como ${newRoleLabel}: ${data.error}`

                        await addUserLogs(uid, logMessage)
                        toast(logMessage, alertErrorStyle)

                        return false
                    }
                }
            })
        }).catch(async (error) => {
            const logMessage = `Erro ao definir ${selectedUser.userName} como ${newRoleLabel}: ${error.code}`

            await addUserLogs(uid, logMessage)
            toast(logMessage, alertErrorStyle)

            return false
        }); 
    }

    const updateManagerMapsIds = async (user, mapsArray) => {
        const userHaveManager = users.managers.find(x => x.operatorsArray.includes(user.uid));

        var newMapArray = [];

        if(userHaveManager && userHaveManager.operatorsArray){
            for(const operatorUid of userHaveManager.operatorsArray){
                const operatorData = users.allUsers.find(x => x.uid == operatorUid);

                if(operatorData){
                    if(operatorUid == user.uid){
                        const mapsIds = arrayHelper.reduceToSimple(mapsArray, 'value');

                        newMapArray = newMapArray.concat(mapsIds)
                    }else{
                        const mapsIds = arrayHelper.reduceToSimple(operatorData.mapsArray, 'value');

                        newMapArray = newMapArray.concat(mapsIds)
                    }
                }
            }
            
            const diffArray = arrayHelper.difference(userHaveManager.mapsArray, newMapArray);

            if(diffArray.length > 0){
                const docRef = doc(db, "managers", userHaveManager.uid);

                try {
                    await updateDoc(docRef, {
                        mapsArray: newMapArray,
                    })
                } catch (error) {
                    return {
                        status: false,
                        error: error
                    }
                }
            }

            return {
                status: true,
                error: null
            }
        }else{
            return {
                status: true,
                error: null
            }
        } 
    }

    return (
        <UsersContext.Provider 
            value={{ 
                users: users,
                getUsersAdmin,

                //Role
                updateRoleToAdmin,
                updateRoleToManager,
                updateRoleToOperator,

                //set
                updateManagerMapsIds,
                clearUsersContext
            }}
        >
            {children}
        </UsersContext.Provider>
    )
}
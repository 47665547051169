import { reportResultType } from "../constants/reportResultType";
import { arrayHelper } from "./arrayHelper";

export const cards = {
    calculateSignedContracts,
    calculateVisitedClients,
    calculateRanking,
    calculateWinnerVisits,
    getReportsOfMonth,
    getAttendOfMonth
}

function calculateVisitedClients(reports) {
    const visitedClientsMonth = [];

    for (const report of reports) {
        if(report.result != reportResultType.DIVERSE_LOCATION){
            visitedClientsMonth.push(report)
        }
    }

    return visitedClientsMonth
}

function calculateSignedContracts(reports) {
    return reports.filter(x => x.result === reportResultType.SIGNED 
        || x.result === reportResultType.RECOMMENDATION_SIGNED);
}

function calculateRanking(signedReports, cardsData) {
    const operators = [];
    const rankingArray = [];

    for (const report of cardsData) {
        const result = operators.find(x => x === report.operatorName);

        if (!result) {
            operators.push({
                operatorName: report.operatorName,
                operatorUid: report.operatorUid
            });
        }
    }

    if (operators.length > 0) {
        for (const operator of operators) {

            const result = signedReports.filter(x => x.operatorUid === operator.operatorUid);

            rankingArray.push({
                operatorName: operator.operatorName,
                operatorUid: operator.operatorUid,
                qtd: result.length,
                array: result
            });
        }
    }

    if (rankingArray.length > 0) {
        return rankingArray.reduce(function (prev, current) {
            return prev.qtd > current.qtd ? prev : current;
        });
    }

    return {
        operatorName: '',
        operatorUid: '',
        qtd: 0,
        array: []
    }
}

function calculateWinnerVisits(visitedReports, cardsData) {
    const operators = [];
    const rankingArray = [];

    for (const report of cardsData) {
        if (report.result != reportResultType.DIVERSE_LOCATION) {

            const result = operators.find(x => x === report.operatorName);

            if (!result) {
                operators.push({
                    operatorName: report.operatorName,
                    operatorUid: report.operatorUid
                });
            }
        }
    }

    if (operators.length > 0) {
        for (const operator of operators) {

            const result = visitedReports.filter(x => x.operatorUid === operator.operatorUid);

            rankingArray.push({
                operatorName: operator.operatorName,
                operatorUid: operator.operatorUid,
                qtd: result.length,
                array: result
            });
        }
    }


    if (rankingArray.length > 0) {
        return rankingArray.reduce(function (prev, current) {
            return prev.qtd > current.qtd ? prev : current;
        });
    }

    return {
        operatorName: '',
        operatorUid: '',
        qtd: 0,
        array: []
    }
}

function getReportsOfMonth(reports) {
    const dateNow = new Date();
    const currentMonth = dateNow.getMonth();

    const result = reports.filter(x => new Date(x.time).getMonth() === currentMonth
        && new Date(x.time).getFullYear() === dateNow.getFullYear());

    return result
}

function getAttendOfMonth(attendClients) {
    const dateNow = new Date();
    const currentMonth = dateNow.getMonth();

    const result = attendClients.filter(x => new Date(x.closeDate).getMonth() === currentMonth
        && new Date(x.closeDate).getFullYear() === dateNow.getFullYear());

    return result
}
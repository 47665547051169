import React, { useContext, useEffect, useState } from "react";

import {
    Container, 
    Header,
    LogsContainer,
    LogItem,
    Circle,
    Line,
    LogsList,
} from './styles';

import { CancelButton, SearchBar, SearchButton, SearchContainer, ClearSearch, TableFooter } from '../../components/CustomTable/styles';

//Firebase and context
import { AuthContext } from '../../contexts/AuthContext';

//Icons
import { FiSearch } from 'react-icons/fi';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

//Custom Components
import ProfileButton from "../../components/ProfileButton";
import CustomLoadingPage from "../../components/CustomLoadingPage";

//MUI
import Menu from '@mui/material/Menu';
import { getSystemLogs } from "../../firebase/logs/system/getSystemLogs";
import { searchHelper } from "../../helpers/searchHelper";
import { arrayHelper } from "../../helpers/arrayHelper";
import { filtersHelper } from "../../helpers/filtersHelper";
import CustomFilterLogs from "../../components/CustomFilterLogs";

const perPage = 40;

const SystemLogsPage = () => {

    const { user } = useContext(AuthContext);

    const [ systemLogs, setSystemLogs ] = useState('');

    const [ filters, setFilters ] = useState([]);

    const [ rangeOfList, setRangeOfList ] = useState({ init: 0, finish: perPage })

    const [ search, setSearch ] = useState('');
    const [ draftSearch, setDraftSearch ] = useState('');
    const [ searchBar, setSearchBar ] = useState(null);
    const openSearch = Boolean(searchBar);

    useEffect(() => {
        (async () => {
            const logs = await getSystemLogs(user);

            setSystemLogs(logs);
        })();
    }, [])

    const clearSearch = () => {
        setSearch('')
        setDraftSearch('')
    }

    const handleSearch = () => {
        setSearchBar(null)
        setSearch(draftSearch)
    }

    useEffect(() => {
        if(!draftSearch){
            clearSearch()
        }
    }, [draftSearch])

    const ordenateLogs = (logs) => {
        const ordenatedArray = logs.sort(function (a, b) {
            if (a > b) {
                return -1;
            }
            if (b > a) {
                return 1;
            } 
            return 0;
        });

        return ordenatedArray
    }

    const processLogs = (logDocs) => {
        var arrayLogs = [];

        for(const log of arrayHelper.ordenateArrayDesc(logDocs)){
            arrayLogs = arrayLogs.concat(ordenateLogs(log.logs))
        }
        
        return arrayLogs
    }

    if(!systemLogs){
        return <Container>
            <CustomLoadingPage />
        </Container>
    }

    const searchLogs = (backupTableData) => {
        const arrayLogs = [];

        for(const log of backupTableData){
            if(searchHelper.searchText(log, search)){
                arrayLogs.push(log)
            }
        }

        return arrayLogs
    }

    const filterByTable = (data) => {
        const filteredDocs = filtersHelper.filterDataTable(data, filters);

        return processLogs(filteredDocs)
    }

    const clearFilters = () => {
        setFilters([])
    }

    const baseTableData = filters.length > 0 ? filterByTable(systemLogs) : processLogs(systemLogs) 

    const backupTableData = processLogs(systemLogs);
    
    const tableData = search ? searchLogs(filterByTable(systemLogs)) : baseTableData

    return (
        <Container>
            <Header>
                <div>
                    <h1>Log do Sistema</h1>
                    <FiSearch 
                        onClick={(event) => {
                            setSearchBar(event.currentTarget);
                        }}
                        size={20} 
                        cursor='pointer' 
                        color='var(--grey2)' 
                    />
                    <ClearSearch>
                        {draftSearch && (
                            <>
                                {draftSearch}
                                <AiOutlineCloseCircle cursor='pointer' onClick={() => clearSearch()} />
                            </>
                        )}
                    </ClearSearch>
                </div>

                <div>
                    <CustomFilterLogs
                        dateField='time'
                        filters={filters}
                        filterTypes={[
                            { headerName: 'Ação', field: 'log' },
                            { headerName: 'Data', field: 'time' },
                            { headerName: 'Operador', field: 'logOperator' }
                        ]}
                        customFilterOptions={[ 
                            { label: 'Entrou no sistema', value: 'acessou' },
                            { label: 'Visualizou as informações', value: 'visualizou' },
                            { label: 'Atendimentos', value: 'atendeu' },
                            { label: 'Criou uma rota', value: 'criou' },
                            { label: 'Erros', value: 'erro' },
                        ]}
                        tableRowsBackup={backupTableData}
                        onChangeFilters={(newFiltersArray) => setFilters(newFiltersArray)}
                        onClearFilters={() => clearFilters()}
                    />
                    <ProfileButton arrowColor='var(grey2)' />
                </div>
            </Header>
            
            <LogsContainer>
                <LogsList>
                    {tableData && tableData.slice(rangeOfList.init, rangeOfList.finish).map((item, index) => (
                        <LogItem key={index}>
                            <div>
                                <Circle />
                                <Line />
                            </div>
                            <h3>{item}</h3>
                        </LogItem>
                    ))}
                </LogsList>
                <TableFooter>
                    <h3>{rangeOfList.init} - {rangeOfList.finish}</h3>
                    <div>
                        <MdKeyboardArrowLeft 
                            size={20}
                            cursor='pointer'
                            onClick={() => {
                                if(rangeOfList.finish == systemLogs.length){
                                    const value = (rangeOfList.finish - rangeOfList.init) + perPage;

                                    const newFinish = rangeOfList.init;

                                    setRangeOfList({ init: rangeOfList.finish - value, finish: newFinish})
                                }else{
                                    if((rangeOfList.init - perPage) >= 0){
                                        setRangeOfList({ init: rangeOfList.init - perPage, finish: rangeOfList.finish-perPage})
                                    }
                                }
                            }}
                        />
                        <MdKeyboardArrowRight 
                            size={20}
                            cursor='pointer'
                            onClick={() => {
                                if(systemLogs.length > perPage){
                                    if((rangeOfList.finish + perPage) <= systemLogs.length){
                                        setRangeOfList({ init: rangeOfList.finish, finish: rangeOfList.finish+perPage})
                                    }else{
                                        if(rangeOfList.finish != systemLogs.length){
                                            const value = rangeOfList.finish+(systemLogs.length - rangeOfList.finish);
        
                                            setRangeOfList({ init: rangeOfList.finish, finish: value})
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                    <h3>{systemLogs ? systemLogs.length : 0} clientes</h3>
                </TableFooter> 
            </LogsContainer>

            <Menu
                id="basic-menu"
                anchorEl={searchBar}
                open={openSearch}
                onClose={() => {
                    setSearchBar(null);
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                PaperProps={{
                    style: {
                        width: 600,
                        height: 40,
                        maxHeight: 40,
                        borderRadius: 20,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        overflow: 'hidden',
                    },
                }}
            >
                <SearchContainer onSubmit={(e) => { 
                    e.preventDefault();
                    handleSearch()
                }}>
                    <FiSearch 
                        size={18} 
                        cursor='pointer' 
                        color='#626F87'
                        strokeWidth='3'
                    />
                     <p>&#8288;</p>
                    <SearchBar>
                        <input 
                            name="draftSearch" 
                            onChange={event => setDraftSearch(event.target.value)}
                            value={draftSearch}
                            placeholder='Buscar...'
                            autoFocus={true}
                        />
                    </SearchBar>
                    <CancelButton onClick={() => {
                        setSearchBar(null);
                    }}>
                        Cancelar
                    </CancelButton>
                    <SearchButton onClick={() => handleSearch()}>
                        Buscar
                    </SearchButton>
                </SearchContainer>
            </Menu>
        </Container>
    )
}

export default SystemLogsPage;
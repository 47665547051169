import { combineComponents } from '../helpers/combineComponents';

import { AuthProvider } from './AuthContext';
import { ClientsProvider } from './ClientsContext';
import { HistoryProvider } from './HistoryContext';
import { MapsProvider } from './MapsContext';
import { ReportsProvider } from './ReportsContext';
import { RoutesProvider } from './RoutesContext';
import { UsersProvider } from './UsersContext';

const providers = [
  AuthProvider,
  RoutesProvider,
  UsersProvider,
  MapsProvider,
  ClientsProvider,
  ReportsProvider,
  HistoryProvider
]
export const AppContextProvider = combineComponents(...providers);
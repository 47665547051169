import React, { useContext, useEffect, useState } from 'react';

import { 
    Container,
    Header,
    ListContainer,
} from './styles';

//Firebase and context
import { doc, updateDoc } from "firebase/firestore";
import db from '../../firebase/config';

//Icons
import { AiOutlineCloseCircle } from 'react-icons/ai';

//Custom components
import DownloadButton from '../DownloadButton';
import ModalReturnItem from '../ModalReturnItem';
import CustomLoading from '../CustomLoading';
import ModalReleaseMap from '../ModalReleaseMap';

//Utils
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import ModalAssignReturn from '../ModalAssignReturn';
import ModalDetailsAdmin from '../ModalDetailsAdmin';
import { alertSuccessStyle } from '../../resources/alertSuccessStyle';
import { alertErrorStyle } from '../../resources/alertErrorStyle';
import { filtersHelper } from '../../helpers/filtersHelper';
import CustomFilterHeader from '../CustomFilterHeader';
import { addClientLogs } from '../../firebase/logs/clients/addClientLogs';
import { AuthContext } from '../../contexts/AuthContext';

function ModalReturnCard({ data, onClose }) {

    const dateNow = new Date();

    const { userData } = useContext(AuthContext);

    //filters
    const [ filters, setFilters ] = useState([])

    const [ modalData, setModalData ] = useState('');

    const [ currentClient, setCurrentClient ] = useState('');

    //Modal
    const [ modalReleaseMap, setModalReleaseMap ] = useState(false);
    const [ modalAssignReturn, setModalAssignReturn ] = useState(false);
    const [ modalFullData, setModalFullData ] = useState(false);

    useEffect(() => {
        setModalData(data.array);
    }, [data]);

    const releaseToMap = async () => {
        const docRef = doc(db, "clientes", currentClient.clientCpf);

        await updateDoc(docRef, {
            selected: '',
            operatorUid: '',
            atraso: false,
            retornoPremium: true,
        }).then(async () => {
            const logMessage = `${currentClient.clientName} foi devolvido ao mapa por ${userData.userName}`
            await addClientLogs(currentClient.clientCpf, logMessage)

            toast(`Cliente ${currentClient.clientName} devolvido ao mapa com sucesso`, alertSuccessStyle)
        }).catch(async (error) => {
            const logMessage = `Erro ao devolver cliente ${currentClient.clientName} ao mapa por ${
                userData.userName}: ${error.code}`
            await addClientLogs(currentClient.clientCpf, logMessage)

            toast(`Erro ao devolver cliente ${currentClient.clientName} ao mapa: ${error.code}`, alertErrorStyle)
        })
        
        setModalReleaseMap(false);
    }

    const assignToOperator = async (operator) => {
        const docRef = doc(db, "clientes", currentClient.clientCpf);

        await updateDoc(docRef, {
            operatorUid: operator.value,
            atraso: false,
            retornoPremium: true,
            forwardTime: dateNow.getTime()
        }).then(async () => {
            const logMessage = `${currentClient.clientName} foi encaminhado para o operador ${
                operator.label} por ${userData.userName}`
            await addClientLogs(currentClient.clientCpf, logMessage)
            
            const toastMessage = `Cliente ${currentClient.clientName} encaminhado para o operador ${
                operator.label} com sucesso`
            toast(toastMessage, alertSuccessStyle)
            
            updateModalData(currentClient.clientCpf);
        }).catch(async (error) => {
            const logMessage = `Erro ao encaminhar cliente ${currentClient.clientName} para o operador ${
                operator.label} por ${userData.userName}: ${error.code}`
            await addClientLogs(currentClient.clientCpf, logMessage)

            const toastMessage = `Erro ao encaminhar cliente ${currentClient.clientName} para o operador ${
                operator.label}: ${error.code}`
            toast(toastMessage, alertErrorStyle)
        })
        setModalAssignReturn(false);
    }

    const updateModalData = (cpf) => {
        const index = modalData.findIndex(x => x.clientCpf === cpf);

        if(index != -1){
            const array1 = modalData.slice(0, index);
            const array2 = modalData.slice(index+1, modalData.length);

            setModalData(array1.concat(array2))
        }
    }

    const filterList = () => {
        return filtersHelper.filterDataTable(modalData, filters)
    }

    const clearFilters = () => {
        setFilters([])
    }

    if(!modalData){
        return (
            <Container>
                <CustomLoading />
            </Container>
        )
    }

    const tableData = filters.length > 0 ? filterList() : modalData

    return (
        <Container>
            <Header>
                <h3>{data.title}</h3>

                    <div>
                    {data.filterTypes && (
                        <CustomFilterHeader 
                            dateField='time'
                            filters={filters}
                            filterTypes={data.filterTypes}
                            tableRowsBackup={modalData}
                            onChangeFilters={(newFiltersArray) => {
                                setFilters(newFiltersArray)
                            }}
                            onClearFilters={() => clearFilters()}
                        />
                    )}
                    <DownloadButton tableColumns={data.tableColumns} tableRows={tableData} /> 
                    <AiOutlineCloseCircle style={{ marginLeft: 10 }} onClick={onClose} strokeWidth={0.5} cursor='pointer' size={22} />
                </div>
            </Header>
            <ListContainer>
                {tableData && tableData.map((item, index) => (
                    <ModalReturnItem 
                        key={index} 
                        data={item} 
                        type={data.type} 
                        columns={data.columns} 
                        onViewFullData={() => {
                            setCurrentClient(item);
                            setModalFullData(true);
                        }}
                        onReleaseMap={() => {
                            setCurrentClient(item)
                            setModalReleaseMap(true)
                        }}
                        onAssignReturn={() => {
                            setCurrentClient(item);
                            setModalAssignReturn(true);
                        }}
                    />
                ))}
            </ListContainer>

            <Modal
                isOpen={modalFullData}
                onRequestClose={() => setModalFullData(false)}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalDetailsAdmin 
                    data={currentClient} 
                    onClose={() => setModalFullData(false)}
                    onStreetView={() => {
                        
                    }}
                />
            </Modal>

            <Modal
                isOpen={modalReleaseMap}
                onRequestClose={() => setModalReleaseMap(false)}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalReleaseMap 
                    clientName={currentClient.clientName} 
                    onCancel={() => setModalReleaseMap(false)}
                    onConfirm={() => releaseToMap()}
                />
            </Modal>

            <Modal
                isOpen={modalAssignReturn}
                onRequestClose={() => setModalAssignReturn(false)}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalAssignReturn 
                    client={currentClient}
                    onCancel={() => setModalAssignReturn(false)}
                    onConfirm={(operator) => assignToOperator(operator)}
                />
            </Modal>
        </Container>
    )
}

export default ModalReturnCard;
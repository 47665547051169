import React, { useEffect, useState } from 'react';

import { 
    DetailsContainer,
    Header, 
    ClientHeader, 
    ClientNameContent, 
    Container, 
    ImageContainer, 
    ImageStreetView, 
    InfoHeader, 
    PointDivider, 
    FullData,
    GenerateReportButton,
    ImageEspecie
} from './styles';

import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FiMapPin } from 'react-icons/fi';
import { TbFileInvoice } from 'react-icons/tb';
import CustomLoading from '../CustomLoading';
import axios from 'axios';
import PointIcon from '../PointIcon';

//utils
import { address } from '../../helpers/address';
import { markers } from '../../helpers/markers';


const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const letras = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

const ClientDetailsModal = ({ data, onClose, onStreetView, onViewFullData }) => {

    const [ loadingData, setLoadingData ] = useState(true);

    const [ imageStreetView, setImageStreetView ] = useState('');

    useEffect(() => {
        (async () => {
            setLoadingData(true);          

            await axios.get(`https://maps.googleapis.com/maps/api/streetview?size=400x400&location=${data.lat},${data.lng}&fov=80&heading=70&pitch=0&key=${apiKey}`, { responseType: "blob" })
                .then((response) => {
                    setImageStreetView(response.data);
                })
                .catch((error) => {
                    
                })
            
            setLoadingData(false);
        })();
    }, [data]);

    if(loadingData){
        return <Container>
            <CustomLoading background='white' />
        </Container>
    }

    return (
        <Container>
            <Header>
                <AiOutlineCloseCircle size={25} style={{ cursor: 'pointer'}} onClick={onClose}/>
            </Header>
            
            <PointIcon 
                size={30}
                textColor='var(--white)'
                fontWeight='400'
                fontSize='12'
                backgroundColor={data.atendido ? 'var(--success)' : 'var(--red)'}
                text={letras[data.index]} 
            />
            <br />
            <ImageContainer>
                <ImageStreetView
                    src={URL.createObjectURL(imageStreetView)}
                    onClick={() => {
                        onStreetView()
                    }}
                />
            </ImageContainer>
            <br />
            <ClientHeader>
                <ImageEspecie>
                    <img 
                        src={markers.getMarkerIconsEspecie(data.especie)} 
                        style={{ width: 34, height: 34, objectFit: 'cover'}} 
                    />
                </ImageEspecie>
                <ClientNameContent>
                    <h4>{data.nome}</h4>
                    <p>{data.especie}</p>
                </ClientNameContent>
            </ClientHeader>
            <br />
            <PointDivider />
            <br />
            <DetailsContainer>
                <InfoHeader>
                    <FiMapPin size={22} color='var(--red)' />
                    <p>Endereço</p>
                </InfoHeader>
                <p>{address.formatEnd(data)}</p>
            </DetailsContainer>

            <DetailsContainer>
                <InfoHeader>
                    <TbFileInvoice size={26} color='var(--red)' style={{ strokeWidth: 1.8 }} />
                    <p>Dados</p>
                </InfoHeader>
                <p>Data de nascimento: {data.dataNasc}</p>
                <p>Idade: {data.idade}</p>
                <p>Nome da mãe: {data.nomeMae}</p>
                {data.email != 'N/D' && (<p>E-mail: {data.email}</p>)}
            </DetailsContainer>

            <FullData onClick={() => onViewFullData(imageStreetView)}>
                Ver dados completos
            </FullData>
        </Container>
    )
}

export default ClientDetailsModal;
import React, { useState, useEffect } from 'react';

import { 
    ModalLogsContent,
    LogsContainer,
    LogItem,
    Circle,
    Line,
    Container,
    Header,
    NumberOfLines
} from '../ModalLogs/styles';

//Icons
import { AiOutlineCloseCircle } from 'react-icons/ai'

//Custom components
import CustomLoading from '../CustomLoading';
import CustomNoRowsOverlay from '../CustomNoRowsOverlay';

//MUI
import Typography from '@mui/material/Typography';
import { getMapLogs } from '../../firebase/logs/maps/getMapLogs';
import { filtersHelper } from '../../helpers/filtersHelper';
import { arrayHelper } from '../../helpers/arrayHelper';
import CustomFilterLogs from '../CustomFilterLogs';

const ModalLogsMaps = ({ mapName, mapId, onClose }) => {

    const [ mapLogs , setMapLogs ] = useState('');

    //filters
    const [ filters, setFilters ] = useState([]);

    useEffect(() => {
        (async () => {
            const logs = await getMapLogs(mapId);    

            setMapLogs(logs)
        })();
    }, []);

    const processLogs = (logDocs) => {
        var arrayLogs = [];

        for(const log of arrayHelper.ordenateArrayDesc(logDocs)){
            arrayLogs = arrayLogs.concat(log.logs.reverse())
        }
        
        return arrayLogs
    }

    if(!mapLogs){
        return (
            <Container>
                <CustomLoading />
            </Container>
        )
    }

    if(mapLogs?.length === 0){
        return (
            <Container>
                <CustomNoRowsOverlay />
            </Container>
        )
    }

    const getNumberOfClientes = (tableData) => {
        const array = [];
        var count = 0;

        for(const item of tableData){
            if(item.includes('clientes')){
                const text = item.split('clientes')

                const text2 = text[0].split(' ');
                
                array.push(text2.at(-2))
            }
        }

        for(const number of array){
            count = count + Number(number)
        }


        return (tableData.length - array.length) + count
    }

    const filterByTable = () => {
        const filteredDocs = filtersHelper.filterDataTable(mapLogs, filters);

        return processLogs(filteredDocs)
    }

    const clearFilters = () => {
        setFilters([])
    }

    const tableData = filters.length > 0 ? filterByTable() : processLogs(mapLogs) 

    const backupTableData = processLogs(mapLogs);
    
    return (
        <Container>
            <Header>
                <h3>{`Logs do mapa ${mapName}`}</h3>
                <div>
                    <CustomFilterLogs 
                        dateField='time'
                        filters={filters}
                        tableRowsBackup={backupTableData}
                        filterTypes={[
                            { headerName: 'Data', field: 'time' },
                            { headerName: 'Ação', field: 'log' },
                        ]}
                        customFilterOptions={[ 
                            { label: 'Importados', value: 'importados' },
                            { label: 'Excluídos', value: 'excluido' },
                        ]}
                        onChangeFilters={(newFiltersArray) => setFilters(newFiltersArray)}
                        onClearFilters={() => clearFilters()}
                    />
                    <AiOutlineCloseCircle 
                        style={{ marginLeft: 10 }} 
                        onClick={onClose} 
                        strokeWidth={0.5} 
                        cursor='pointer' 
                        size={22} 
                    />
                </div>
            </Header>
            <hr />
            <ModalLogsContent>
                <LogsContainer>
                    <Typography sx={{ width: '100%', flexShrink: 0, fontWeight: 'bold', color: '#9a4749'}}>
                        {tableData.map((item, index) => (
                            <LogItem key={index}>
                                <div>
                                    <Circle />
                                    <Line />
                                </div>
                                <h3>{item}</h3>
                            </LogItem>
                        ))}
                    </Typography>
                </LogsContainer> 
                <NumberOfLines>
                    {getNumberOfClientes(tableData)} clientes
                </NumberOfLines>
            </ModalLogsContent>
        </Container>
    );
}

export default ModalLogsMaps;
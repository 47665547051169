import React, { useEffect, useRef, useState } from 'react';

import { 
    Container, 
    FieldText, 
    TextInput, 
    ActionButtons,
    CancelButton,
    SaveButton,
    UserPhoto,
    UserPhotoContainer
} from './styles';

//Icons
import { AiOutlineUser } from 'react-icons/ai';

//Custom components
import ModalHeader from '../ModalHeader';
import CustomDivider from '../CustomDivider';

//MUI
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

//Utils
import Select from 'react-select';
import CustomLoading from '../CustomLoading';
import { toast } from 'react-toastify';
import { alertErrorStyle } from '../../resources/alertErrorStyle';
import { userRoleOptions } from '../../constants/userRoleOptions';
import { avatar } from '../../helpers/avatar';

function ModalFormUser({ data, title, onClose, loading, onSave }) {

    const imageInput = useRef(null);

    const [ newUser, setNewUser ] = useState('');
    const [ imageFile, setImageFile ] = useState('');

    useEffect(() => {
        setNewUser(data)
    }, [data])

    const handleInputChange = (field, event) => {
        setNewUser({
            ...newUser,
            [field]: event.target.value,
        })
    }

    const blobToBase64 = blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
            resolve(reader.result);
            };
        });
    };

    const handleUserFunction = (selectedOption) => {
        setNewUser({
            ...newUser,
            type: selectedOption.value,
        })
    };

    const customStyle = {      
        container: (base) => ({
            ...base,
            borderRadius: 40,
            marginBottom: 5,
        }),
        control: (base) => ({
            ...base,
            padding: 3,
            borderRadius: 40            
        }),
        multiValue: (base) => ({
            ...base,
            borderRadius: 40,
            backgroundColor: '#ECF2FB'
        }),
    } 

    if(loading){
        <Container>
            <CustomLoading />
        </Container>
    }


    return (
        <Container>
            <ModalHeader title={title} onClose={onClose} />


            <UserPhotoContainer>
                <UserPhoto>
                    {newUser && newUser.img64
                        ? <img 
                            src={avatar.getAvatarByData(newUser)} 
                            style={{ height: '100%', width: '100%', borderRadius: 100}} 
                            alt='img img' 
                        />
                        : <AiOutlineUser size={20} />
                    }
                </UserPhoto>
                <input 
                    type='file'
                    accept={['.png', '.jpg', '.jpeg']}
                    id='imageFile'
                    ref={imageInput}
                    style={{display:'none'}}
                    onChange={async (e) => {
                        e.preventDefault(); 
                        
                        blobToBase64(e.target.files[0]).then(async (res) => {
                            setImageFile(res);
                            setNewUser({
                                ...newUser,
                                img64: res.slice(23, res.length)
                            })
                        }).catch(() => {
                            toast('Erro ao inserir imagem', alertErrorStyle)
                        })
                    }}
                />
                <h4 onClick={() => imageInput.current.click()}>Alterar imagem</h4>
            </UserPhotoContainer>

            <FieldText>
                Nome
            </FieldText>
            <TextInput>
                <input 
                    name="userName" 
                    onChange={event => handleInputChange('userName', event)}
                    placeholder='Nome do usuário'
                    defaultValue={newUser && newUser.userName}
                />
            </TextInput>

            <FieldText>
                Tipo de usuário:
            </FieldText>
            <Select 
                id='select-function' 
                options={userRoleOptions}  
                value={userRoleOptions.find(x => x.value == newUser.type)} 
                styles={customStyle}
                onChange={(selectedOption) => {
                    handleUserFunction(selectedOption);
                }}
            />
            <FieldText>
                Status
            </FieldText>
            <FormGroup>
                <FormControlLabel 
                    control={<Switch checked={!newUser.disabled} />} 
                    onChange={() => setNewUser({ ...newUser, disabled: !newUser.disabled})}
                    label={!newUser.disabled ? 'Ativo' : 'Inativo'}
                    style={{
                        color: 'var(--blue4)'
                    }}
                />
            </FormGroup>

            <FieldText>
                Login
            </FieldText>
            <TextInput>
                <input 
                    name="login" 
                    onChange={event => handleInputChange('login', event)}
                    placeholder='Login do usuário'
                    defaultValue={newUser && newUser.login}
                />
            </TextInput>

            <FieldText>
                Email
            </FieldText>
            <TextInput>
                <input 
                    name="email" 
                    onChange={event => handleInputChange('email', event)}
                    placeholder='E-mail do usuário'
                    defaultValue={newUser && newUser.email}
                    disabled={data ? true : false}
                />
            </TextInput>

            <FieldText>
                Senha
            </FieldText>
            <TextInput>
                <input 
                    name="password" 
                    onChange={event => handleInputChange('password', event)}
                    placeholder='Nova senha do usuário'
                    defaultValue={newUser && newUser.password}
                />
            </TextInput>
            
            <br />
            <CustomDivider />
            <ActionButtons>
                {loading
                    ? <CustomLoading />
                    : <>
                        <CancelButton onClick={() => {
                            onClose()
                        }}>
                            <p>Cancelar</p>
                        </CancelButton>
                        <SaveButton onClick={async () => {
                            await onSave(newUser)                               
                        }}>
                            <p>Salvar</p>
                        </SaveButton>
                    </>
                }
             </ActionButtons>    
        </Container>
    )
}

export default ModalFormUser;
import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    min-height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 25px;

    background: var(--background);
`;

export const ImageLogin = styled.div`
    width: 60%;
    height: 526px;
    padding: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ContainerLogin = styled.div`
    width: 40%;
    display: flex;
`;

export const WrapLogin = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: min(100%, 413px);
    height: 549px;
    background: var(--white);

    padding: 77px 55px 33px 55px;
    /* shadowcard */

    box-shadow: 0px 4px 20px rgba(35, 54, 63, 0.05);
    border-radius: 20px;
`;

export const LoginForm = styled.form`
    width: 100%;
    margin-top: 40px;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
`;

export const LogoContainer = styled.div`
    width: 90px;

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FieldText = styled.p`
    height: 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 24px;

    margin-bottom: 5px;

    color: #4A5E83;
`;

export const TextInput = styled.div`
    box-sizing: border-box;
    display: flex;

    width: 312px;
    height: 44px;
    align-items: center;

    border: 1px solid var(--grey1);
    border-radius: 100px;
`;

export const ShowPassword = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 10px 0 10px 0;
    cursor: pointer;

    p {
        height: 24px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 24px;
        text-align: center;

        margin-bottom: 5px;

        color: #4A5E83;
    }
`;

export const LoginButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    padding: 12px 115px 12px 115px;

    background: #DE6161;
    box-shadow: 0px 7px 10px rgba(228, 73, 73, 0.25);
    border-radius: 100px;

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        text-align: center;

        /* 5 */

        color: #FFFFFF;

    }
`;

export const LoadingContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 13px;
    margin-top: 20px;
`;
import { useContext, useEffect } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { mappers } from "../helpers/mappers";
import { collection, query, onSnapshot, getDocs } from "firebase/firestore";
import db from '../firebase/config';
import { orderBy } from "lodash";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { arrayHelper } from "../helpers/arrayHelper";
import { HistoryContext } from "../contexts/HistoryContext";

export default function useRemove(){
    const { isAdmin } = useContext(AuthContext);

    const auth = getAuth();

    const { removeHistory, setRemoveHistory } = useContext(HistoryContext);

    //get remove history admin
    const getRemoveHistory = async () => {   
        const consulta = query(collection(db, 'removeHistory'), orderBy("time", "desc"));
        await onSnapshot(consulta, async (querySnapshot) => {

            const array = []

            for(const doc of querySnapshot.docs){
                const data = doc.data();

                const removedClientsList = await getRemovedClients(doc.id);
                const notRemovedClientsList = await getNotRemovedClients(doc.id);

                array.push({ 
                    ...data, 
                    docId: doc.id,
                    removedClientsList: removedClientsList,
                    notRemovedClientsList: notRemovedClientsList
                });
            }

            setRemoveHistory(arrayHelper.ordenateArrayDesc(array, 'time'));
        }, error => console.log("getRemoveHistory error", error));
    }

    const getRemovedClients = async (docId) => {        
        const consulta = query(collection(db, `removeHistory/${docId}/removedClients`));

        const querySnapshot = await getDocs(consulta);
    
        const mappedDocs = mappers.mapFirebaseDoc(querySnapshot)
            
        return mappedDocs
    }

    const getNotRemovedClients = async (docId) => {        
        const consulta = query(collection(db, `removeHistory/${docId}/notRemovedClients`));

        const querySnapshot = await getDocs(consulta);
    
        const mappedDocs = mappers.mapFirebaseDoc(querySnapshot)
            
        return mappedDocs
    }

    const removeClientsFromMap = async (data) => {
        let response = { success: false, error: null }

        await auth.currentUser.getIdToken(/* forceRefresh */ true).then(async function(idToken) {
            const functions = getFunctions();
            const removeClientsFromMap = httpsCallable(functions, 'removeClientsFromMap', { timeout: 540000 });
            return await removeClientsFromMap({idToken: idToken, body: data }).then(async result => {
                const data = result.data;

                if(data.success){
                    response = { success: true, error: null }
                }else{
                    response = { success: false, error: data.error }
                }
            })
        }).catch(async (error) => {
            response = { success: false, error: error.code}
        }); 

        return response
    }

    const undoRemoveClientsFromMap = async (data) => {
        let response = { success: false, error: null }

        await auth.currentUser.getIdToken(/* forceRefresh */ true).then(async function(idToken) {
            const functions = getFunctions();
            const undoRemoveClientsFromMap = httpsCallable(functions, 'undoRemoveClientsFromMap', { timeout: 540000 });
            return await undoRemoveClientsFromMap({idToken: idToken, body: data }).then(async result => {
                const data = result.data;

                if(data.success){
                    response = { success: true, error: null }
                }else{
                    response = { success: false, error: data.error }
                }
            })
        }).catch(async (error) => {
            response = { success: false, error: error.code}
        }); 

        return response
    }

    useEffect(() => {
        if(isAdmin){
            if(!removeHistory){
                getRemoveHistory()
            }
        }
    }, [])

    return {
        removeHistory: removeHistory,
        removeClientsFromMap,
        undoRemoveClientsFromMap,
        loading: !removeHistory ? true : false
    }
}
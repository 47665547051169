import React from 'react';

import { Container } from './styles';
import CustomLoading from '../CustomLoading';

function CustomCardRoot({ children, loading, onClick }) {

    if(loading){
        return (
            <Container>
                <CustomLoading size={30} />
            </Container>
        )
    }

    return (
        <Container onClick={onClick}>
            {children}
        </Container>
    )
}

export default CustomCardRoot;
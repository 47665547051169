import React from 'react';

import { CardTitle } from './styles';

function CustomCardTitle({ color, imgSrc, title }) {
    return (
        <CardTitle color={color}>
            <img src={imgSrc} alt="icon icon" />
            <h1>{title}</h1>
        </CardTitle>
    )
}

export default CustomCardTitle;
import React, { useContext } from 'react';

import { 
    Container,
    Name,
    Especie,
    Result,
    Date,
    Options
} from './styles';

//Firebase and context
import { UsersContext } from '../../contexts/UsersContext';

//Icons
import { GoPrimitiveDot } from 'react-icons/go';
import { AiOutlineEye } from 'react-icons/ai';
import { FiMap } from 'react-icons/fi';
import { BsArrow90DegRight } from 'react-icons/bs';

//MUI
import { Avatar } from '@mui/material';
import StatusIcon from '../StatusIcon';
import { datetime } from '../../helpers/datetime';
import { avatar } from '../../helpers/avatar';
import { especiesHelper } from '../../helpers/especiesHelper';

const options = [
    "Contrato fechado",
    "Contrato fechado por indicação",
    "Endereço não encontrado",
    "Litispendência",
    "Já tem advogado",
    "Retornar",
    "Não tem direito",
    "Não tem interesse",
    "Localidade diversa",
]

const optionsColors = [
    "var(--success)",
    "var(--success)",
    "var(--red)",
    "var(--red)",
    "var(--red)",
    "var(--yellow)",
    "var(--red)",
    "var(--red)",
    "var(--red)",
]

function ModalReturnItem({ data, type, columns, onViewFullData, onReleaseMap, onAssignReturn }) {

    const { users } = useContext(UsersContext);

    return (
        <Container columns={columns}>
            <Name>{data.clientName}</Name>
            <Especie 
                color={especiesHelper.getEspecieTag(data.especie).tagColor} 
                textColor={especiesHelper.getEspecieTag(data.especie).textColor}
            >
                {data.especie}
            </Especie>
            {(type == 'realized' || type == 'closed') && (
                <Date color='var(--grey2)'>
                    {datetime.getDate(data.time)}
                </Date>
            )}
            {type == 'pending' && (
                <>
                    {data.dataRetorno || data.horaRetorno 
                        ? <Date color='var(--grey2)'>
                            {data.dataRetorno ? data.dataRetorno : null} {data.horaRetorno ? data.horaRetorno : null}
                        </Date>
                        : <h4>Sem data e hora</h4>
                    }
                </>
            )}
            {type == 'inRoute' && (
                <Date color='var(--grey2)'>
                    {datetime.getDate(data.time)}
                </Date>
            )}
            {type == 'expired' && (
                <Date color='var(--red)'>
                    {datetime.getDate(data.time)}
                </Date>
            )}
            {type == 'inRoute' && (
                <StatusIcon status={data.routeStatus} />
            )}
            {type == 'realized' && (
                <Result color={optionsColors[options.findIndex(x => x == data.result)]}>
                    <GoPrimitiveDot size={10} color={optionsColors[options.findIndex(x => x == data.result)]} />
                    {data.result}
                </Result>
            )}
            <div>
                <Avatar
                    alt="Avatar"
                    src={avatar.getUserAvatar(users.operators, data.operatorUid)}
                    sx={{ 
                        width: 20, 
                        height: 20, 
                        borderRadius: 100, 
                        borderColor: 'white', 
                        borderWidth: 10 
                    }}
                />
                <h4>
                    {data.operatorName}
                </h4>
            </div>
            <Options>
                <AiOutlineEye 
                    onClick={onViewFullData} 
                    cursor='pointer' 
                    size={20} 
                    color='#808B9F' 
                    fill='#808B9F'
                />
                {type == 'expired' && (
                    <FiMap 
                        onClick={onReleaseMap} 
                        cursor='pointer' 
                        size={15} 
                        color='#808B9F' 
                    />
                )}
                {type == 'expired' && (
                    <BsArrow90DegRight 
                        onClick={onAssignReturn} 
                        cursor='pointer' 
                        size={15} 
                        color='#808B9F' 
                        strokeWidth='0.5'
                    />
                )}
            </Options>
        </Container>
    )
}

export default ModalReturnItem;
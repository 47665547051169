import React, { useState } from 'react';

//Styles
import { 
    ActionsContainer, 
    Container, 
    ExpandedButton, 
    SavedPointsContainer, 
    SavedRouteDetails, 
    SavedRouteHeader, 
    SavedRouteInfo, 
    SavedRouteName 
} from './styles';

//Icons
import { BiUser } from 'react-icons/bi';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { TbCalendarMinus, TbPencil } from 'react-icons/tb';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { FiTrash2 } from 'react-icons/fi';
import ElipseIcon from '../ElipseIcon';
import CustomDivider from '../CustomDivider';
import StatusIcon from '../StatusIcon';
import SavedRoutePoint from '../SavedRoutePoint';

//utils
import { datetime } from '../../helpers/datetime';

const SavedRouteCard = ({ route, onEdit, onCancel, onTrace }) => {

    const [ expanded, setExpanded ] = useState(false);

    return (
        <Container>
            <SavedRouteHeader>
                <SavedRouteName>
                    <p>{route.name}</p>
                    <ElipseIcon 
                        text={route.points.length} 
                        size={17} 
                        background='var(--blue3)' 
                        fontWeight='400' 
                        fontSize={12} 
                    />
                </SavedRouteName>
                {route.status == 'aguardando' && (
                    <ActionsContainer>
                        <TbPencil 
                            onClick={onEdit}
                            size={22} 
                            style={{ strokeWidth: 1.5, cursor: 'pointer' }} 
                        />
                        <FiTrash2 
                            onClick={onCancel}
                            color='var(--red)' 
                            size={20} 
                            style={{ strokeWidth: 1.5, cursor: 'pointer' }}
                        />
                    </ActionsContainer>
                )}
            </SavedRouteHeader>
            <SavedRouteDetails>
                <SavedRouteInfo>
                    <BiUser />
                    <p>Operador: {route.operatorName}</p>
                </SavedRouteInfo>
                <SavedRouteInfo>
                    <AiOutlineClockCircle />
                    <p>Status:</p>
                    <StatusIcon status={route.status} />
                </SavedRouteInfo>
                <SavedRouteInfo>
                    <TbCalendarMinus />
                    <p>Data de criação: {datetime.getFullDate(route.time)}</p>
                </SavedRouteInfo>
            </SavedRouteDetails>
            {expanded && (<>
                <CustomDivider />
                <SavedPointsContainer>
                    {route.points.length != 0 && route.points.map((item, index) => (
                        <>
                            
                            <SavedRoutePoint 
                                letter={index} 
                                clientEnd={item}
                                clientName={item.nome}
                                especie={item.especie}
                            />
                        </>
                    ))}
                </SavedPointsContainer >
            </>)}
            <ExpandedButton onClick={() => {
                    onTrace(!expanded);
                    setExpanded(!expanded)
                }}>
                <p>{expanded ? 'Recolher' : 'Expandir' }</p>
                {expanded
                    ? <IoIosArrowUp />
                    : <IoIosArrowDown  />
                }
            </ExpandedButton>
        </Container>
    )
}

export default SavedRouteCard;
import React, { useState } from 'react'
import { ModalContainer, ModalListContainer, ModalListHeader, ModalItem, SubHeader, SwitchLogType, SwitchButton } from './styles'
import { TbCalendar } from 'react-icons/tb'
import { AiOutlineEye, AiOutlineUser } from 'react-icons/ai'
import CustomDivider from '../../../../components/CustomDivider'
import { datetime } from '../../../../helpers/datetime'

import cpfIcon from '../../../../assets/cpfIcon.svg'

import Modal from 'react-modal';
import ModalDetailsAdmin from '../../../../components/ModalDetailsAdmin'
import { toast } from 'react-toastify'
import { alertErrorStyle } from '../../../../resources/alertErrorStyle'
import ModalHeader from '../../../../components/ModalHeader'
import useMapClients from '../../../../hooks/useMapClients'

export default function ModalViewClients({ history, onClose }) {

    const { getClientByCpf } = useMapClients();

    const [ switchSelected, setSwitchSelected ] = useState('removedClients');

    const modalData = { removedClients: history.removedClientsList, notRemovedClients: history.notRemovedClientsList }

    const [ modalDetails, setModalDetails ] = useState({ data: '', visible: false })

    const handleModalDetails = async (data) => {
        const clientData = await getClientByCpf(data.cpf);

        if(clientData){
            setModalDetails({
                data: clientData,
                visible: true
            })
        }else{
            toast('Erro ao obter dados do cliente', alertErrorStyle)
        }
    }

    return (
        <ModalContainer>
            <ModalHeader 
                title={`Remoção de clientes ${datetime.getFullDate(history.time)}`}
                onClose={onClose}
            />
            <SwitchLogType>
                <SwitchButton 
                    selected={switchSelected == 'removedClients' ? true : false}
                    onClick={() => setSwitchSelected('removedClients')}
                >
                    Clientes removidos
                    {switchSelected == 'removedClients' && (<div></div>)}
                </SwitchButton>
                <SwitchButton 
                    selected={switchSelected == 'notRemovedClients' ? true : false}
                    onClick={() => setSwitchSelected('notRemovedClients')}
                >
                    Clientes não removidos
                    {switchSelected == 'notRemovedClients' && (<div></div>)}
                </SwitchButton>
            </SwitchLogType>
            <SubHeader>
                <div>
                    <h4>Retirada em:</h4>
                    <TbCalendar />
                    <h4>{datetime.getDate(history.time)}</h4>
                </div>
                <div>
                    <h4>Usuário:</h4>
                    <AiOutlineUser />
                    <h4>{history.userName}</h4>
                </div>
                {history.undo && (
                    <>
                        <div>
                            <h4>Desfeita em:</h4>
                            <TbCalendar />
                            <h4>{datetime.getDate(history.undo.time)}</h4>
                        </div>
                        <div>
                            <h4>Usuário:</h4>
                            <AiOutlineUser />
                            <h4>{history.undo.userName}</h4>
                        </div>
                    </>
                )}
            </SubHeader>
            <ModalListHeader>
                <h3>Nome</h3>
                <h3>CPF</h3>
                <h3>Ações</h3>
            </ModalListHeader>
            <ModalListContainer>
                {modalData && modalData[switchSelected] && modalData[switchSelected].map((item, index) => (
                    <div key={index}>
                        <ModalItem key={index}>
                            <h4>{item.nome}</h4>
                            <div>
                                <img src={cpfIcon} alt='cpf' />
                                <h4>{item.cpf}</h4>
                            </div>
                            <div>
                                <AiOutlineEye
                                    onClick={() => handleModalDetails(item)}
                                    cursor='pointer'
                                    size={18}
                                    color='#808B9F'
                                    fill='#808B9F'
                                    style={{ marginLeft: 10 }}
                                />
                            </div>
                        </ModalItem>
                        {index < modalData[switchSelected].length && (<CustomDivider width='98%' />)}
                    </div>
                ))}
            </ModalListContainer>

            <Modal
                isOpen={modalDetails.visible}
                onRequestClose={() => setModalDetails({ data: '', visible: false })}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalDetailsAdmin 
                    data={modalDetails.data} 
                    onClose={() => setModalDetails({ data: '', visible: false })}
                    onStreetView={() => {}}
                />
            </Modal>
        </ModalContainer>
    )
}

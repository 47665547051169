import React, { useContext } from 'react'

import { Container, HeaderContent, FooterContent, Divider } from './styles';

import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../contexts/AuthContext';
import { ClientsContext } from '../../contexts/ClientsContext';
import { MapsContext } from '../../contexts/MapsContext';
import { RoutesContext } from '../../contexts/RoutesContext';
import { UsersContext } from '../../contexts/UsersContext';

import { MdExitToApp } from 'react-icons/md'
import { Avatar } from '@mui/material';
import { addUserLogs } from '../../firebase/logs/users/addUserLogs';
import { avatar } from '../../helpers/avatar';
import { ReportsContext } from '../../contexts/ReportsContext';
import { HistoryContext } from '../../contexts/HistoryContext';

const LogoutMenu = () => {
  const auth = getAuth();

  const { user, userData, clearAuthContext } = useContext(AuthContext);
  const { clearClientsContext }  = useContext(ClientsContext);
  const { clearMapsContext } = useContext(MapsContext);
  const { clearReportsContext } = useContext(ReportsContext);
  const { clearRoutesContext } = useContext(RoutesContext);
  const { clearUsersContext } = useContext(UsersContext);
  const { clearHistoryContext } = useContext(HistoryContext);
  

  const navigate = useNavigate();

  const clearAllContexts = () => {
    clearAuthContext();
    clearClientsContext();
    clearMapsContext();
    clearReportsContext();
    clearRoutesContext();
    clearUsersContext();
    clearHistoryContext();
  }

  const logout = async () => {
    clearAllContexts();
    const logMessage = `${userData.userName} saiu do sistema (Web)`;
            
    await addUserLogs(user.uid, logMessage)
    await signOut(auth).then(async () => {
        navigate('/')
    }).catch((error) => {
        alert('Ocorreu um erro ao sair.')
    });
  }

  return (
    <Container>
        <HeaderContent>
            <Avatar
                alt="Remy Sharp"
                src={avatar.getAvatarByData(userData)}
                sx={{ width: 46, height: 46, borderRadius: 2, borderColor: 'white', borderWidth: 20 }}
            />
            <div className='info'>
                <h4>{userData.userName}</h4>
                <h5>{userData.email}</h5>
            </div>
        </HeaderContent>
        <Divider />
        <FooterContent onClick={() => logout()}>
            <MdExitToApp size={18} color='#DE6161' fill='#DE6161'/>
            <h4>Sair</h4>
        </FooterContent>
    </Container>
  )
}

export default LogoutMenu;
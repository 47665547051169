import React, { useState, useMemo, useEffect, useContext } from 'react';

import { 
    Container, 
    RouteBar, 
    MapContainer, 
    Title,
    FieldText,
    TextInput,
    ActionButtons,
    CancelButton,
    SaveButton,
    LogsButton,
    CloseButton
} from './styles';

//Firebase and context
import { AuthContext } from '../../contexts/AuthContext';
import { collection, setDoc, getDoc, doc, updateDoc, addDoc, arrayUnion, writeBatch } from "firebase/firestore";
import db from '../../firebase/config';

//Google Maps API
import { 
    GoogleMap, 
    useJsApiLoader, 
    Marker, 
    DirectionsRenderer,  
    StreetViewPanorama 
} from '@react-google-maps/api';

//Icons
import greenMarker from '../../assets/greenMarker.png'
import CustomLoading from '../CustomLoading';
import AdminRouteCard from '../AdminRouteCard';
import CustomDivider from '../CustomDivider';
import clockIcon from '../../assets/clockIcon2.svg';
import { AiOutlineCloseSquare, AiOutlineCloseCircle } from 'react-icons/ai';
import { FiCheckSquare } from 'react-icons/fi';

//Custom components
import ClientDetailsModal from '../ClientDetailsModal';
import ModalDetailsAdmin from '../ModalDetailsAdmin';

//Utils
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import ModalConfirm from '../ModalConfirm';
import ModalLogsRoutes from '../ModalLogsRoutes';
import { datetime } from '../../helpers/datetime';
import { addRouteLogs } from '../../firebase/logs/routes/addRouteLogs';

const letras = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

function ModalViewRoute({ routeData, onClose }) {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    const { isAdmin } = useContext(AuthContext)

    //Modal
    const [ modalFullData, setModalFullData ] = useState(false);
    const [ modalConfirm, setModalConfirm ] = useState(false);
    const [ modalData, setModalData ] = useState('');
    const [ modalLogs, setModalLogs ] = useState(false);

    //Route edit
    const [ edit, setEdit ] = useState(false);
    const [ newData, setNewData ] = useState('');
    const [ arrayRemoved, setArrayRemoved ] = useState([]);

    //Map
    const [ loadingData, setLoadingData ] = useState(true);
    const [ mapRef, setMapRef ] = useState(null);
    const [ lastCenter, setLastCenter ] = useState('');
    const center = useMemo(() => ({lat: -8.113334, lng: -35.059530}), []);

    //Marker
    const [ currentClient, setCurrentClient ] = useState('');
    const [ viewDetails, setViewDetails ] = useState('');
    const [ streetView, setStreetView ] = useState(false);
    const [ directionsResponse, setDirectionsResponse ] = useState(null);

    useEffect(() => {
        setNewData(routeData)
        setTimeout(() => {
            setLoadingData(false);
            traceRoute(routeData.points)
        }, 300)
    }, [routeData])

    const traceRoute = async (points) => {

        const formatedOrigin = {
            lat: points[0].lat,
            lng: points[0].lng,
        }

        const formatedDestination = {
            lat: points.slice(-1)[0].lat,
            lng: points.slice(-1)[0].lng,
        }

        const waypoints = points.slice(1, -1);
        const formatedWaypoints = [];
        

        for(const p in waypoints){
            formatedWaypoints.push({
                location: {lat: waypoints[p].lat, lng: waypoints[p].lng },
            })
        }

        const google = window.google;
        const directionsService = new google.maps.DirectionsService();
        const results = await directionsService.route({
            origin: formatedOrigin,
            destination: formatedDestination,
            waypoints: formatedWaypoints,
            travelMode: google.maps.TravelMode.DRIVING,
        })

        return setDirectionsResponse(results);
    }

    const handleInputRouteName = (event) => {

        setNewData({
            ...newData,
            name: event.target.value
        })
    }

    const removePoint = (client) => {
        const index = newData.points.findIndex(x => x.cpf == client.cpf);

        setArrayRemoved(arrayRemoved.concat(newData.points[index]))
        setCurrentClient('');

        if(index != -1){
            const array1 = newData.points.slice(0, index);
            const array2 = newData.points.slice(index+1, newData.points.length);

            setNewData({
                ...newData,
                points: array1.concat(array2),
            })
        }
    }

    const saveRoute = async () => {

        if(arrayRemoved){
            const batch = writeBatch(db);
            
            for(const point of arrayRemoved){
                const docRef = doc(db, "clientes", point.cpf);
        
                batch.update(docRef, {
                    selected: "",
                });

                const dateNow = new Date();
                const stringDate = datetime.getFullDate(dateNow.getTime()); 
    
                const logMessage = `${point.nome} foi liberado para o mapa pelo 
                    devido a edição da rota ${newData.name} pelo admin`
    
                const log = `${stringDate} -> ${logMessage}` 
    
                const docRefLogs = doc(db, "clientesLogs", point.cpf);
    
                batch.update(docRefLogs, {
                    logs: arrayUnion(log)
                });
            }

            batch.commit()

        }

        const docRef = doc(db, "rotas", routeData.docId);

        await updateDoc(docRef, {
            name: newData.name,
            points: newData.points
        }).then(async () => {
            const logMessage = `${routeData.name} atualizada pelo admin`
    
            await addRouteLogs(routeData.docId, logMessage)

            toast(`Rota ${routeData.name} atualizada com sucesso`, { 
                style: {
                    background: 'var(--success)',
                    color: 'white',
                },
                progressStyle: {
                    background: 'white'
                },
                icon: <FiCheckSquare />
            })
        }).catch(async (error) => {
            const logMessage = `${routeData.name} falha ao atualizar rota pelo admin: ${error.code}`

            await addRouteLogs(routeData.docId, logMessage)
            
            return toast(`Erro ao atualizar rota ${routeData.name} ${error.code}`, { 
                style: {
                    background: 'var(--red)',
                    color: 'white',
                },
                progressStyle: {
                    background: 'white'
                },
                icon: <AiOutlineCloseSquare />
            })
        })

        setEdit(false);
        traceRoute(newData.points)
    }

    const tryDeleteRoute = async () => {

        const batch = writeBatch(db);
        const dateNow = new Date();

        for(const point of newData.points){
            const docRef = doc(db, "clientes", point.cpf);

            batch.update(docRef, {
                selected: "",
            });

            
            const stringDate = datetime.getFullDate(dateNow.getTime()); 

            const logMessage = `${point.nome} foi liberado para o mapa pelo 
                devido ao cancelamento da rota ${newData.name} pelo admin`

            const log = `${stringDate} -> ${logMessage}` 

            const docRefLogs = doc(db, "clientesLogs", point.cpf);

            batch.update(docRefLogs, {
                logs: arrayUnion(log)
            });
        }
        
        const routesRef = doc(db, "rotas", newData.docId);
        batch.update(routesRef, {
            status: "cancelado",
            routeCloseDate: dateNow.getTime(),
        });

        batch.commit().then(async () => {
            const logMessage = `${newData.name} cancelada pelo admin`
    
            await addRouteLogs(newData.docId, logMessage)
    
            toast("Rota cancelada com sucesso", { 
                style: {
                    background: 'var(--success)',
                    color: 'white',
                },
                progressStyle: {
                    background: 'white'
                },
                icon: <FiCheckSquare />
            })
        }).catch(async (error) => {
            const logMessage = `${newData.name} Erro ao cancelar rota pelo admin: ${error.code}`
        
            await addRouteLogs(newData.docId, logMessage)
    
            return toast(`Erro ao cancelar rota ${error.code}`, { 
                style: {
                    background: 'var(--red)',
                    color: 'white',
                },
                progressStyle: {
                    background: 'white'
                },
                icon: <AiOutlineCloseSquare />
            })
        })

        setModalConfirm(false)
        onClose()
    }

    if(loadingData){
        return (
            <Container>
                <CustomLoading />
            </Container>
        )
    }
    
    return (
        <Container>
            <RouteBar>
                {!edit
                    ? <>
                        <Title>
                            Detalhe da rota
                        </Title>
                        <AdminRouteCard
                            route={newData}
                            onCancel={() => {
                                setModalData({
                                    text: `Tem certeza que deseja cancelar a rota ${routeData.name}?`,
                                    option: 'Sim'
                                })
                                setModalConfirm(true)
                            }}
                            onEdit={() => {
                                setEdit(true);
                            }}
                        />
                        <CustomDivider />
                        {isAdmin && (
                            <LogsButton onClick={() => setModalLogs(true)}>
                                <img src={clockIcon} style={{ height: 20, width: 20 }} alt='icon icon' />
                                Ver log da rota
                            </LogsButton>
                        )}
                    </>
                    : <>
                        <Title>
                            Editar rota
                        </Title>
                        <FieldText>
                            Nome da rota
                        </FieldText>
                        <TextInput>
                            <input 
                                name="routeName" 
                                onChange={event => handleInputRouteName(event)}
                                placeholder='Nome da rota'
                                defaultValue={newData.name}
                            />
                        </TextInput>
                        <AdminRouteCard
                            route={newData}
                            onCancel={() => {
                                setModalData({
                                    text: `Tem certeza que deseja cancelar a rota ${routeData.name}?`,
                                    option: 'Sim'
                                })
                                setModalConfirm(true)
                            }}
                            onEdit={() => {
                                setEdit(true);
                            }}
                            edit={edit}
                            onRemove={(client) => {
                                removePoint(client)
                            }}
                        />
                        <CustomDivider />
                        <ActionButtons>
                            <CancelButton onClick={() => {
                                setNewData(routeData);
                                setEdit(false)
                            }}>
                                <p>Cancelar</p>
                            </CancelButton>
                            <SaveButton onClick={async () => {
                                await saveRoute()                               
                            }}>
                                <p>Salvar</p>
                            </SaveButton>
                        </ActionButtons>
                    </>
                }
                
            </RouteBar>
            {isLoaded && loadingData === false && center && (
                <MapContainer>
                    <GoogleMap
                        onLoad={map => {
                            setMapRef(map)
                        }}
                        mapContainerStyle={{
                            width: '100%', 
                            height: '100%', 
                            borderTopRightRadius: 40,
                            borderBottomRightRadius: 40
                        }}
                        center={lastCenter ? lastCenter : center}
                        zoom={11}
                        clickableIcons={false}
                        options={{
                            disableDefaultUI: true,
                        }}
                    >
                        <CloseButton onClick={onClose}>
                            <AiOutlineCloseCircle size={30} />
                        </CloseButton>

                        {currentClient && (
                            <ClientDetailsModal 
                                data={currentClient}
                                onClose={() => {
                                    setCurrentClient('')
                                }}
                                onStreetView={() => {
                                    if(streetView == true){
                                        setStreetView(false);
                                        setTimeout(async () => {
                                            setStreetView(true)
                                        }, 500)
                                    }else{
                                        setStreetView(!streetView)
                                    }
                                }}      
                                onViewFullData={() => {
                                    setModalFullData(true)
                                }}          
                            />
                        )}

                        {newData && newData.points.length > 0                     
                            ?( newData.points.map((cliente, index) => (
                                cliente.lat != null && cliente.lng != null
                                ?
                                <Marker 
                                    key={cliente.cpf} 
                                    position={{lat: cliente.lat, lng: cliente.lng }}
                                    onClick={async () => {
                                        setLastCenter(mapRef.center)
                                        setViewDetails(true);
                                        setCurrentClient({
                                            ...cliente,
                                            index: index,
                                        });                                 
                                    }}
                                    zIndex={5000}
                                    options={{
                                        label: {
                                            text: letras[index],
                                            color: cliente.atendido ? 'transparent' : 'white',
                                            fontWeight: 'bold',
                                        },
                                        optimized: true,
                                        icon: cliente.atendido ? greenMarker : null
                                    }}
                                />
                                : null
                            )))
                            : (
                                <></>
                            )
                        }
                        {streetView === true && (
                            <StreetViewPanorama onCloseclick={() => alert('fechou')} position={{lat: currentClient.lat, lng: currentClient.lng}} visible={streetView}/>
                        )}           
                        {directionsResponse && <DirectionsRenderer options={{suppressMarkers: true}} directions={directionsResponse} />}         
                    </GoogleMap>
                </MapContainer>
            )}
            <Modal
                isOpen={modalFullData}
                onRequestClose={() => setModalFullData(false)}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalDetailsAdmin 
                    data={currentClient} 
                    onClose={() => setModalFullData(false)}
                    onStreetView={() => {
                        setModalFullData(false);
                        if(streetView == true){
                            setStreetView(false);
                            setTimeout(async () => {
                                setStreetView(true)
                            }, 500)
                        }else{
                            setStreetView(!streetView)
                        }
                    }}
                />
            </Modal>
            
            <Modal
                isOpen={modalConfirm}
                onRequestClose={() => setModalConfirm(false)}
                overlayClassName="modal-overlay"
                className="modal-content-alert"
                contentLabel="Example Modal"
            >
                <ModalConfirm 
                    data={modalData} 
                    onCancel={() => {
                        setModalConfirm(false);
                    }}
                    onConfirm={async () => {
                        await tryDeleteRoute();
                        // alert('Rota excluída')
                    }}
                />
            </Modal>

            <Modal
                isOpen={modalLogs}
                onRequestClose={() => setModalLogs(false)}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalLogsRoutes
                    routeName={routeData.name}
                    routeDocId={routeData.docId} 
                    onClose={() => setModalLogs(false)}
                />
            </Modal>
        </Container>
    ) 
}

export default ModalViewRoute;
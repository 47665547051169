import CustomCardContent from "./CustomCardContent";
import CustomCardDivider from "./CustomCardDivider";
import CustomCardRoot from "./CustomCardRoot";
import CustomCardTitle from "./CustomCardTitle";

export const CustomCard = {
    Root: CustomCardRoot,
    Title: CustomCardTitle,
    Divider: CustomCardDivider,
    Content: CustomCardContent
}
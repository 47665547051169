import { useContext, useEffect } from "react";
import { AuthContext } from "../contexts/AuthContext";

import { collection, query, onSnapshot, where, doc, getDoc } from "firebase/firestore";
import db from '../firebase/config';
import { arrayHelper } from "../helpers/arrayHelper";
import { ClientsContext } from "../contexts/ClientsContext";
import { mappers } from "../helpers/mappers";

export default function useMapClients(){
    const { user, isAdmin, isManager, userData } = useContext(AuthContext);

    const { mapClients, setMapClients, mapClientsUser, setMapClientsUser } = useContext(ClientsContext);

    const getClientsUser = async (mapsArray, especies, uid) => {
        const mapsValues = arrayHelper.reduceToSimple(mapsArray, 'value');

        const consulta = query(collection(db, 'clientes'), 
            where("selected", "==", ""), where("mapId", "in", mapsValues));

        await onSnapshot(consulta, (querySnapshot) => {
            const database = [];
            
            const data = querySnapshot.docs;
            
            for(const i in data){
                const client = data[i].data();
                const mapName = mapsArray.find(x => x.value == client.mapId)

                if(client.selected != 'done' && 
                    client.endNotFound != true && 
                    client.endNotFoundOperator != true && 
                    client.differentLocation != true &&
                    (client.operatorUid === uid || client.operatorUid === "" || !client.operatorUid))
                    
                {
                    if(mapName && mapName.label && especies[mapName.label]){
                        if(client.specialIcon){
                            if(especies[mapName.label].includes('ESPECIAL')){
                                database.push({...client, docId: data[i].id});
                            }
                        }else{
                            if(especies[mapName.label].includes(client.especie)){
                                database.push({...client, docId: data[i].id});
                            }else{
                                //verifica se é um retorno, pois pode estar no mapa independente do benefício
                                if(client.retornos > 0 && client.operatorUid === uid){
                                    database.push({...client, docId: data[i].id})
                                }
                            }
                        } 
                    }
                }
            }
            
            setMapClientsUser(database);
        }, error => console.log("getClientsUser error", error));
    }
    
    const getClientsAdmin = async () => {        
        const consulta = query(collection(db, 'clientes'), 
            where("selected", "not-in", ["done", "foraDoMapa"]));
        await onSnapshot(consulta, (querySnapshot) => {
            const mappedDocs = mappers.mapFirebaseDoc(querySnapshot)

            const mappedClients = mappers.mapClientsFromMap(mappedDocs)
            
            setMapClients(mappedClients);
        }, error => console.log("getClientsAdmin error", error));
    }

    //Get clientes do gerente
    const getClientsManager = async (mapsArray) => {     
        
        if(!mapsArray || mapsArray?.length == 0){
            return setMapClients([]);
        } 

        const mapsIds = arrayHelper.reduceToSimple(mapsArray, 'value');

        const consulta = query(collection(db, 'clientes'), where('mapId', 'in', mapsIds), where("selected", "!=", "done"));
        await onSnapshot(consulta, (querySnapshot) => {
            const mappedDocs = mappers.mapFirebaseDoc(querySnapshot)

            const mappedClients = mappers.mapClientsFromMap(mappedDocs)
      
            setMapClients(mappedClients);
        }, error => console.log("getClientsManager error", error));
    }

    const getClientByCpf = async (cpf) => {
        const docRef = doc(db, "clientes", cpf);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const client = {...docSnap.data(), docId: docSnap.id };
            
            return client
        } else {
            return null
        }
    }

    useEffect(() => {
        if(isAdmin){
            if(!mapClients){
                getClientsAdmin();
            }
        }
    
        if(isManager){
            if(userData && !mapClients){
                getClientsManager(userData.mapsArray);
            }
        }
    
        if(!isAdmin && !isManager){
            if(!mapClientsUser && user && userData){
                getClientsUser(userData.mapsIds, userData.especies, user.uid)
            }
        }
    }, [])

    return {
        mapClients: mapClients,
        mapClientsUser: mapClientsUser,
        getClientByCpf,
        loadingAttendClients: !mapClients || !mapClientsUser ? true : false
    }
}
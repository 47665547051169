import styled from 'styled-components';

export const SubtitleArea = styled.div`
    padding: 0rem 1.3rem 0rem 3.1rem;
`;

export const ChartArea = styled.div`
    /* position: relative; */
    overflow: visible;
    
    .chartVoid {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
        margin-top: 10px;
    }
`;

export const TooltipArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ color }) => color};
    box-shadow: 0px 7px 20px rgba(208, 75, 75, 0.4);
    border-radius: 100px;
    width: 45px;
    height: 25px;

    p {
        color: var(--white);
        font-size: 16px;
        font-weight: bold;
    }
`;

export const SvgArea = styled.div`
    position: absolute;
`;

export const ChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-top: 10px;

    padding: 20px;

    background: #FFFFFF;
    /* shadowcard */

    box-shadow: 0px 4px 20px rgba(35, 54, 63, 0.05);
    border-radius: 20px;
`;

export const ChartHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 15px;
    /* margin-bottom: 20px; */

    div {
        display: flex;

        align-items: center;

        gap: 15px;
    }

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        color: #808B9F;
    }
`;

export const RangeSelectContainer = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: right;

    gap: 5px;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        margin-right: 5px;
    
        color: #808B9F;
    }
`;

export const RangeSelectButton = styled.div`
    display: flex;
    align-items: center;
    padding: 7px 18px;

    cursor: pointer;
    user-select: none;

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        
        color: ${props => props.selected ? 'var(--white)' : 'var(--grey2)'};
    }
    /* 3 */

    background: ${props => props.selected ? 'var(--red)' : 'var(--white)'};
    
    border: ${props => props.selected ? 'none' : '1px solid #B4C1D5'};
    border-radius: 100px;

`;

export const ChartLinesContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 10px;

    gap: 10px;
`;

export const ChartLineFilter = styled.div`
    display: flex;
    
    align-items: center;
    gap: 10px;

    padding: 5px 0;
`;

export const ChartLineTitle = styled.div`
    display: flex;
    
    align-items: center;
    gap: 10px;

    cursor: pointer;
    user-select: none;
    
    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        
        /* 2 */
        border-bottom: ${props => props.selected ? `2px solid ${props.color}` : 'none'};
        color: #808B9F;
    }
`;

export const ChartLineItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 7px 18px;

    /* width: 180px; */
    height: 38px;
    cursor: pointer;
    user-select: none;

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        margin-right: 5px;
    
        color: #808B9F;
    }
    /* 3 */
    
    border: 1px solid #B4C1D5;
    border-radius: 100px;
`;

export const AddLineButton = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    gap: 10px;

    cursor: pointer;
    user-select: none;

    margin-left: 20px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */


    /* 2 */

    color: #808B9F;
`;

export const InforCardRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
`;

export const PercentContainer = styled.div`
    width: 80%;
`;

export const FirstChartHorizontal = styled.div`
    height: 600px;
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ChartsContainer = styled.div`
    border-radius: 20px;
    /* padding: 20px; */
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
`;

export const FilteredChartContainer = styled.div`
    padding: 20px;
    border-radius: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    background: #fff;
`;

export const ChartVerticalContainer = styled.div`
    padding: 5px;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
`;

export const LegendContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;

    h2 {
        text-align: center;
        margin-bottom: 10px;
    }

    div {
        align-self: center;
        width: 80%;
    }
`;

export const LegendContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-top: 10px;
    
    h5 {
        align-self: center;
    }
`;

import React from 'react'

import * as S from './styles';
import { Avatar } from '@mui/material';
import { avatar } from '../../../../helpers/avatar';

export default function OperatorLocation({ operator, users, onClose }) {
    return (
        <S.Container title={operator?.operatorName} onClick={onClose}>
            <S.Content>
                <Avatar
                    style={{
                        width: '100%',
                        flex: 1,
                        borderRadius: 0
                    }}
                    src={avatar.getUserAvatar(users.operators, operator?.operatorUid)}
                />
                <h4>{operator.operatorName}</h4>
            </S.Content>
        </S.Container>
    )
}

import React, { useContext, useState } from 'react';

import { Container, ButtonFilter, TextClearFilters, SelectContent, ConfirmButton } from './styles';

//icons
import { IoMdArrowDropdown } from 'react-icons/io';
import { BsEraser } from 'react-icons/bs';

//Utils
import Select from 'react-select'

//Mui components
import Menu from '@mui/material/Menu';
import DateFilter from '../DateFilter';
import { filtersHelper } from '../../helpers/filtersHelper';
import { arrayHelper } from '../../helpers/arrayHelper';
import { UsersContext } from '../../contexts/UsersContext';

const CustomFilterLogs = ({ filters, filterTypes, tableRowsBackup, 
    dateField, defaultTitle, customFilterOptions, onChangeFilters, onClearFilters }) => {
    
    const { users } = useContext(UsersContext);
    
    const [ selectedField, setSelectedField ] = useState('');

    const [ options, setOptions ] = useState([]);

    const [ filterCalendar, setFilterCalendar ] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const clearAllFilters = () => {
        onClearFilters();
    }

    const handleMenuClose = () => {
        setSelectedField('');
        setAnchorEl(null);
    }

    const handleMenuOpen = (event, headerName) => {
        if(headerName == 'Data'){
            setFilterCalendar(true);
        }else{
            setFilterCalendar(false)
        }

        setSelectedField(event.currentTarget.id)
        setAnchorEl(event.currentTarget);
    };

    const handleChangeFilters = async (newValueArray, field) => {
        
        const exists = filters.findIndex(x => x.field === field);

        if(exists != -1){
            if(newValueArray.length > 0){
                const newObject = {
                    ...filters[exists],
                    values: newValueArray
                }
    
                const newArray = arrayHelper.replaceItemOfArray(filters, exists, newObject)
    
                onChangeFilters(newArray)
            }else{
                const newArray = arrayHelper.removeItemOfArray(filters, exists)
    
                onChangeFilters(newArray)
            }
        }else{
            const newObject = {
                field: field,
                type: 'string',
                values: newValueArray
            }
            
            onChangeFilters([...filters, newObject])
        }
    }

    const handleChangeDateFilter = (calendarValues, field) => {
        const exists = filters.findIndex(x => x.field === field);

        if(exists != -1){
            if(calendarValues?.selectedStart || calendarValues?.selectedEnd){
                const newObject = {
                    ...filters[exists],
                    values: calendarValues
                }
    
                const newArray = arrayHelper.replaceItemOfArray(filters, exists, newObject)
    
                onChangeFilters(newArray)
            }else{
                const newArray = arrayHelper.removeItemOfArray(filters, exists)
    
                onChangeFilters(newArray)
            }
        }else{
            const newObject = {
                field: field,
                type: 'date',
                reportType: 'atendimentos',
                values: calendarValues
            }
            
            onChangeFilters([...filters, newObject])
        }
    }

    const buttonHandle = (label, field) => {
        const exists = filters.find(x => x.field === field);
        if(exists){
            if(exists.type === 'string'){
                if(exists.values?.length > 1){
                    return `${exists.values[0].value} +${exists.values.length -1}`
                }else{
                    return `${exists.values[0].value}`
                }
            }

            if(exists.type === 'date'){
                const selectedStart = exists?.values?.selectedStart;
                const selectedEnd = exists?.values?.selectedEnd;

                if(selectedStart && selectedEnd?.day){
                    return `${selectedStart.day}/${selectedStart.month}/${selectedStart.year} - 
                        ${selectedEnd.day}/${selectedEnd.month}/${selectedEnd.year}`
                }

                if(selectedStart){
                    return `${selectedStart.day}/${selectedStart.month}/${selectedStart.year}`
                }
            }
        }else{
            if(field == 'time' && defaultTitle){
                return defaultTitle
            }
            return `${label}`
        }
    }

    const proccessOperatorOptions = () => {
        const array = [];

        for(const user of users?.allUsers){
            array.push({
                label: user.userName,
                value: user.userName
            })
        }
        const ordenatedArray = array.sort(function(a,b) {
            return a.label > b.label ? 1 : a.label < b.label ? -1 : 0;
        });
        
        return ordenatedArray
    }

    const getOptions = (selectedField) => {
        if(selectedField === 'logOperator'){
            setOptions(proccessOperatorOptions())
        }else{
            if(customFilterOptions){
                setOptions(customFilterOptions)
            }else{
                setOptions(filtersHelper.getFilterOptions(tableRowsBackup, selectedField, filters))
            }
        }
    }

    const customStyle = {      
        container: (base) => ({
            ...base,
            borderRadius: 40
        }),
        control: (base) => ({
            ...base,
            padding: 3,
            borderRadius: 40
        }),
        multiValue: (base) => ({
            ...base,
            borderRadius: 40
        }),
        menuList: (base) => ({
            ...base,
            height: 260
        })
    }  

    return <Container>
            {filterTypes.map((item, index) => (
                <ButtonFilter 
                    key={index} 
                    id={item.field} 
                    onClick={(event) => handleMenuOpen(event, item.headerName)}
                >
                    <h4>{buttonHandle(item.headerName, item.field)}</h4>
                    <IoMdArrowDropdown style={{ cursor: 'pointer'}} size={18} color='#808B9F' fill='#808B9F' />
                </ButtonFilter>
            ))}

            <TextClearFilters onClick={() => clearAllFilters()}>
                <BsEraser style={{ cursor: 'pointer', marginRight: -5 }} />
                Limpar
            </TextClearFilters>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                style={{  zIndex: 100000, }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                PaperProps={{  
                    style: {  
                        width: 400,
                        height: 400,
                        padding: 10,
                        borderRadius: 15,
                    },  
                }} 
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {filterCalendar
                    ?<DateFilter 
                        filters={filters}
                        dateField={dateField}
                        onFilterDate={(values) => handleChangeDateFilter(values, dateField)} 
                        clearDateFilter={() => handleChangeDateFilter(null, dateField)}
                        onClose={handleMenuClose}
                    />
                    :<SelectContent>
                        <Select 
                            isClearable={false} 
                            isSearchable={true} 
                            defaultValue={selectedField && filters && filtersHelper.getDefaultValues(selectedField, filters)}
                            closeMenuOnSelect={false} 
                            options={options} 
                            isMulti={true} 
                            styles={customStyle}
                            autoFocus={true}
                            openMenuOnFocus={true}
                            onMenuOpen={() => getOptions(selectedField)}
                            onChange={(item) => handleChangeFilters(item, selectedField)}
                        />
                        <ConfirmButton onClick={() => handleMenuClose()}>
                            <h3>OK</h3>
                        </ConfirmButton>
                    </SelectContent>
                }
            </Menu>
    </Container>
}

export default CustomFilterLogs;
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { mappers } from "../helpers/mappers";
import { collection, query, onSnapshot, where } from "firebase/firestore";
import db from '../firebase/config';
import { arrayHelper } from "../helpers/arrayHelper";
import { ClientsContext } from "../contexts/ClientsContext";

export default function useExpiredReturns(){
    const { isAdmin, isManager, userData } = useContext(AuthContext);

    const { expiredReturns, setExpiredReturns } = useContext(ClientsContext);

    //get expired returns admin
    const getExpiredReturnsAdmin = async () => {
        const consulta = query(collection(db, 'clientes'), 
            where("selected", "not-in", ["done", "foraDoMapa"]), where('atraso', '==', true));
        await onSnapshot(consulta, (querySnapshot) => {
            
            const mappedDocs = mappers.mapFirebaseDoc(querySnapshot)
            
            setExpiredReturns(mappedDocs);
        }, error => console.log("getExpiredReturnsAdmin error", error));
    }

    //get mapClients manager
    const getExpiredReturnsManager = async (mapsArray) => {     
        
        if(!mapsArray || mapsArray?.length == 0){
            return setExpiredReturns([]);
        } 

        const mapsIds = arrayHelper.reduceToSimple(mapsArray, 'value');

        const consulta = query(collection(db, 'clientes'), where('mapId', 'in', mapsIds), 
            where('atraso', '==', true));
        onSnapshot(consulta, (querySnapshot) => {

            const mappedDocs = mappers.mapFirebaseDoc(querySnapshot)
      
            setExpiredReturns(mappedDocs);
        }, error => console.log("getExpiredReturnsManager error", error));
    }

    const fetchExpiredReturns = () => {
        if(isAdmin){
            if(!expiredReturns){
                getExpiredReturnsAdmin()
            }
        }
    
        if(isManager){
            if(!expiredReturns){
                getExpiredReturnsManager(userData.mapsArray);
            }
        }
    }

    return {
        expiredReturnsClients: expiredReturns,
        fetchExpiredReturns,
        loading: !expiredReturns ? true : false
    }
}
import React, { useContext, useState } from 'react';

import { CardsContainer } from './styles';

import { CustomCard } from '../../../../components/CustomCard';

import checkedIcon from '../../../../assets/checkedIcon.svg'
import listIcon from '../../../../assets/listIcon.svg'
import winnerIcon from '../../../../assets/winnerIcon.svg';
import medalIcon from '../../../../assets/medalIcon.svg';
import { avatar } from '../../../../helpers/avatar';

import Modal from 'react-modal';

//Utils
import tableColumnsReturns from '../../../../resources/tableColumnsReturns.json';
import ModalDashboardCard from '../../../../components/ModalDashboardCard';
import { Avatar } from '@mui/material';
import { UsersContext } from '../../../../contexts/UsersContext';

const defaultModalData = {
    array: [],
    title: '',
    type: null,
    columns: 5,
    filterTypes: null,
    tableColumns: tableColumnsReturns,
    visible: false
}

function ReportsCards({ cardsData }) {
    const { users } = useContext(UsersContext);

    const [ modalData, setModalData ] = useState(defaultModalData);    
    
    const closeModal = () => {
        setModalData(defaultModalData)
    }

    return (
        <>
            <CardsContainer>
                <CustomCard.Root
                    loading={!cardsData}
                    onClick={() => {
                        setModalData({
                            array: cardsData.signed,
                            title: 'Contratos fechados',
                            type: null,
                            columns: 5,
                            filterTypes: null,
                            tableColumns: tableColumnsReturns,
                            visible: true
                        })
                    }}
                >
                    <CustomCard.Title
                        color="var(--success)"
                        title="Contratos fechados"
                        imgSrc={checkedIcon}
                    />
                    <CustomCard.Divider />
                    <CustomCard.Content
                        color="var(--success)"
                        value={cardsData.signed?.length}
                        description='contratos'
                    />
                </CustomCard.Root>

                <CustomCard.Root
                    loading={!cardsData}
                    onClick={() => {
                        setModalData({
                            array: cardsData.visitedClients,
                            title: 'Clientes visitados',
                            type: null,
                            columns: 5,
                            filterTypes: null,
                            tableColumns: tableColumnsReturns,
                            visible: true
                        })
                    }}
                >
                    <CustomCard.Title
                        color="var(--blue4)"
                        title="Clientes visitados"
                        imgSrc={listIcon}
                    />
                    <CustomCard.Divider />
                    <CustomCard.Content
                        color="var(--blue4)"
                        value={cardsData.visitedClients?.length}
                        description='clientes'
                    />
                </CustomCard.Root>

                <CustomCard.Root
                    loading={!users}
                    onClick={() => {
                        setModalData({
                            array: cardsData.winnerSigned ? cardsData.winnerSigned.array : [],
                            title: 'Ranking do mês',
                            type: 'contratos',
                            columns: 4,
                            filterTypes: null,
                            tableColumns: tableColumnsReturns,
                            visible: true
                        })
                    }}
                >
                    <CustomCard.Title
                        color="var(--success)"
                        title="Ranking do mês"
                        imgSrc={winnerIcon}
                    />
                    <CustomCard.Divider />
                    <CustomCard.Content
                        color="var(--success)"
                        value={cardsData.winnerSigned ? cardsData.winnerSigned?.qtd : "0"}
                        description='contratos'
                        Avatar={cardsData.winnerSigned && cardsData.winnerSigned?.qtd > 0 && (
                            <>
                                <Avatar
                                    alt="Remy Sharp"
                                    sx={{ width: 26, height: 26, cursor: 'pointer' }}
                                    src={avatar.getUserAvatar(users?.operators, cardsData.winnerSigned.operatorUid)}
                                />

                                <h1 className="operatorName">{cardsData.winnerSigned.operatorName}</h1>
                            </>
                        )}
                    />
                </CustomCard.Root>

                <CustomCard.Root
                    loading={!users}
                    onClick={() => {
                        setModalData({
                            array: cardsData.winnerVisits ? cardsData.winnerVisits.array : [],
                            title: 'Campeão de visitas',
                            type: 'clientes',
                            columns: 4,
                            filterTypes: null,
                            tableColumns: tableColumnsReturns,
                            visible: true
                        })
                    }}
                >
                    <CustomCard.Title
                        color="var(--blue4)"
                        title="Campeão de visitas"
                        imgSrc={medalIcon}
                    />
                    <CustomCard.Divider />
                    <CustomCard.Content
                        color="var(--blue4)"
                        value={cardsData.winnerVisits ? cardsData.winnerVisits?.qtd : "0"}
                        description='clientes'
                        Avatar={cardsData.winnerVisits && cardsData.winnerVisits?.qtd > 0 && (
                            <>
                                <Avatar
                                    alt="Remy Sharp"
                                    sx={{ width: 26, height: 26, cursor: 'pointer' }}
                                    src={avatar.getUserAvatar(users?.operators, cardsData.winnerVisits.operatorUid)}
                                />

                                <h1 className="operatorName">{cardsData.winnerVisits.operatorName}</h1>
                            </>
                        )}
                    />
                </CustomCard.Root>
            </CardsContainer>

            <Modal
                isOpen={modalData.visible}
                onRequestClose={() => closeModal()}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalDashboardCard data={modalData} onClose={() => closeModal()}/>
            </Modal>
        </>
    )
}

export default ReportsCards;
import { useContext, useEffect } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { mappers } from "../helpers/mappers";
import { collection, query, onSnapshot, getDocs } from "firebase/firestore";
import db from '../firebase/config';
import { orderBy } from "lodash";
import { getAuth } from "firebase/auth";
import { arrayHelper } from "../helpers/arrayHelper";
import { getFunctions, httpsCallable } from "firebase/functions";
import { HistoryContext } from "../contexts/HistoryContext";

export default function useImport(){
    const { isAdmin } = useContext(AuthContext);

    const auth = getAuth();

    const { importHistory, setImportHistory } = useContext(HistoryContext);

    //get import history admin
    const getImportHistory = async () => {
        const consulta = query(collection(db, 'importHistory'), orderBy("time", "desc"));
        await onSnapshot(consulta, async (querySnapshot) => {
            
            const array = []

            for(const doc of querySnapshot.docs){
                const data = doc.data();

                const importedClientsList = await getImportedClients(doc.id);
                const notImportedClientsList = await getNotImportedClients(doc.id);

                array.push({ 
                    ...data, 
                    docId: doc.id,
                    importedClientsList: importedClientsList,
                    notImportedClientsList: notImportedClientsList
                });
            }

            setImportHistory(arrayHelper.ordenateArrayDesc(array, 'time'));
        }, error => console.log("getImportHistory error", error));
    }

    const getImportedClients = async (docId) => {        
        const consulta = query(collection(db, `importHistory/${docId}/importedClients`));

        const querySnapshot = await getDocs(consulta);
    
        const mappedDocs = mappers.mapFirebaseDoc(querySnapshot)
            
        return mappedDocs
    }

    const getNotImportedClients = async (docId) => {        
        const consulta = query(collection(db, `importHistory/${docId}/notImportedClients`));

        const querySnapshot = await getDocs(consulta);
    
        const mappedDocs = mappers.mapFirebaseDoc(querySnapshot)
            
        return mappedDocs
    }

    const undoImportClients = async (data) => {
        let response = { success: false, error: null }

        await auth.currentUser.getIdToken(/* forceRefresh */ true).then(async function(idToken) {
            const functions = getFunctions();
            const undoImportClients = httpsCallable(functions, 'undoImportClients', { timeout: 540000 });
            return await undoImportClients({idToken: idToken, body: data }).then(async result => {
                const data = result.data;

                if(data.success){
                    response = { success: true, error: null }
                }else{
                    response = { success: false, error: data.error }
                }
            })
        }).catch(async (error) => {
            response = { success: false, error: error.code}
        }); 

        return response
    }

    useEffect(() => {
        if(isAdmin){
            if(!importHistory){
                getImportHistory()
            }
        }
    }, [])

    return {
        importHistory: importHistory,
        undoImportClients,
        loading: !importHistory ? true : false
    }
}
import React from 'react';

//Styles
import { Container, RoutePointItemLeft, RoutePointItemInfo, PointDot, IconContainer } from './styles';

//Icons
import PointIcon from '../PointIcon';
import { FiTrash2 } from 'react-icons/fi';

//utils
import { address } from '../../helpers/address';

const letras = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

const AdminRoutePoint = ({ letter, clientName, client, edit, onRemove }) => {

    return <Container>
            <RoutePointItemLeft>
                <PointIcon 
                    text={letras[letter]} 
                    size={18} 
                    backgroundColor={client.atendido ? 'var(--success)' : 'var(--red)'}
                />

                <PointDot />
                <PointDot />
                <PointDot />
                <PointDot />
                <PointDot />
                <PointDot />
            </RoutePointItemLeft>
            <RoutePointItemInfo>
                <h4>{clientName}</h4>
                <p>{address.formatEnd(client)}</p>
            </RoutePointItemInfo>
            {edit && !client.atendido && (
                <IconContainer>
                    <FiTrash2 
                        onClick={() => onRemove(client)}
                        color='var(--red)' 
                        size={20} 
                        style={{ strokeWidth: 1.5, cursor: 'pointer' }}
                    />
                </IconContainer>
            )}
    </Container>
}

export default AdminRoutePoint;
import styled from "styled-components";

export const Container = styled.div`
    height: 60px;
    width: 20px;
`;

export const Content = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;

    z-index: 9999;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;

    h4 {
        max-height: 30px;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        /* identical to box height */
        text-align: center;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        margin-top: 2px;

        color: #4D6289;
    }
`;
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    margin-top: 30px;

    max-height: 40vh;
`;

export const List = styled.div`
    overflow: auto;
`;

export const TableColumns = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr) repeat(3, 0.5fr) 0.5fr;
    min-height: 46px;
    width: 100%;

    padding: 0 10px;

    align-items: center;
    /* justify-content: center; */

    background: rgba(236, 242, 251, 0.5);
    border-radius: 10px;

    color: #808B9F;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;

        color: #808B9F;
    }
`;

export const ContentHeader = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    margin-bottom: 40px;
    gap: 20px;

    div {
        display: flex;
        
        align-items: center;

        gap: 20px;
    }

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        line-height: 22px;
        /* identical to box height */


        /* 1 */

        color: #4A5E83;
    }
`;
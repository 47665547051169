import React, { useState, useEffect } from 'react';

import { 
    ModalLogsContent,
    LogsContainer,
    Container,
    LogItem,
    Circle,
    Line,
    Header,
    ClearSearch
} from '../ModalLogs/styles';

import { CancelButton, SearchBar, SearchButton, SearchContainer } from '../CustomTable/styles';

//Icons
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FiSearch } from 'react-icons/fi';

//Custom components
import CustomLoading from '../CustomLoading';
import CustomNoRowsOverlay from '../CustomNoRowsOverlay';

//MUI
import Menu from '@mui/material/Menu';
import { getUserLogs } from '../../firebase/logs/users/getUserLogs';
import { searchHelper } from '../../helpers/searchHelper';
import { arrayHelper } from '../../helpers/arrayHelper';
import { filtersHelper } from '../../helpers/filtersHelper';
import CustomFilterLogs from '../CustomFilterLogs';


const ModalLogsUser = ({ userName, userId, onClose }) => {

    const [ userLogs, setUserLogs ] = useState([]);

    //filters
    const [ filters, setFilters ] = useState([]);

    const [ search, setSearch ] = useState('');
    const [ searchBar, setSearchBar ] = useState(null);
    const openSearch = Boolean(searchBar);

    useEffect(() => {
        (async () => {
            const logs = await getUserLogs(userId);    

            setUserLogs(logs)
        })();
    }, []);

    useEffect(() => {
        if(!search){
            setSearch('')
        }
    }, [search])

    const processLogs = (logDocs) => {
        var arrayLogs = [];

        for(const log of arrayHelper.ordenateArrayDesc(logDocs)){
            arrayLogs = arrayLogs.concat(log.logs.reverse())
        }
        
        return arrayLogs
    }

    if(!userLogs){
        return (
            <Container>
                <CustomLoading />
            </Container>
        )
    }

    if(userLogs?.length === 0){
        return (
            <Container>
                <CustomNoRowsOverlay />
            </Container>
        )
    }

    const searchLogs = (backupTableData) => {
        const arrayLogs = [];

        for(const log of backupTableData){
            if(searchHelper.searchText(log, search)){
                arrayLogs.push(log)
            }
        }

        return arrayLogs
    }

    const filterByTable = (data) => {
        const filteredDocs = filtersHelper.filterDataTable(data, filters);

        return processLogs(filteredDocs)
    }

    const clearFilters = () => {
        setFilters([])
    }

    const baseTableData = filters.length > 0 ? filterByTable(userLogs) : processLogs(userLogs) 

    const backupTableData = processLogs(userLogs);
    
    const tableData = search ? searchLogs(filterByTable(userLogs)) : baseTableData

    return (
        <Container>
            <Header>
                <div>
                    <h3>{`Logs do usuário ${userName}`}</h3>
                    <FiSearch 
                        onClick={(event) => {
                            setSearchBar(event.currentTarget);
                        }}
                        size={20} 
                        cursor='pointer' 
                        color='var(--grey2)' 
                    />
                    <ClearSearch>
                        {search && (
                            <>
                                {search}
                                <AiOutlineCloseCircle cursor='pointer' onClick={() => {
                                    setSearch('')
                                }} />
                            </>
                        )}
                    </ClearSearch>
                </div>
                <div>
                    <CustomFilterLogs 
                        dateField='time'
                        filters={filters}
                        filterTypes={[
                            { headerName: 'Data', field: 'time' },
                            { headerName: 'Ação', field: 'log' },
                        ]}
                        customFilterOptions={[ 
                            { label: 'Entrou no sistema', value: 'acessou' },
                            { label: 'Saiu do sistema', value: 'saiu' },
                            { label: 'Visualizou as informações', value: 'visualizou' },
                            { label: 'Atendimentos', value: 'atendeu' },
                            { label: 'Erros', value: 'erro' },
                        ]}
                        tableRowsBackup={backupTableData}
                        onChangeFilters={(newFiltersArray) => setFilters(newFiltersArray)}
                        onClearFilters={() => clearFilters()}
                    />
                    <AiOutlineCloseCircle 
                        style={{ marginLeft: 10 }} 
                        onClick={() => onClose()} 
                        strokeWidth={0.5} 
                        cursor='pointer' 
                        size={22} 
                    />
                </div>
            </Header>
            <hr />
            <ModalLogsContent>
                <LogsContainer>
                    {tableData.map((item, index) => (
                        <LogItem key={index}>
                            <div>
                                <Circle />
                                <Line />
                            </div>
                            <h3>{item}</h3>
                        </LogItem>
                    ))}
                </LogsContainer>
            </ModalLogsContent>

            <Menu
                id="basic-menu"
                anchorEl={searchBar}
                open={openSearch}
                onClose={() => {
                    setSearchBar(null);
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                PaperProps={{
                    style: {
                        width: 600,
                        height: 40,
                        maxHeight: 40,
                        borderRadius: 20,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        overflow: 'hidden',
                    },
                }}
            >
                <SearchContainer onSubmit={(e) => { 
                    e.preventDefault();
                    searchLogs(backupTableData)
                    setSearchBar(null);
                }}>
                    <FiSearch 
                        size={18} 
                        cursor='pointer' 
                        color='#626F87'
                        strokeWidth='3'
                    />
                     <p>&#8288;</p>
                    <SearchBar>
                        <input 
                            name="search" 
                            onChange={event => setSearch(event.target.value)}
                            value={search}
                            placeholder='Buscar...'
                            autoFocus={true}
                        />
                    </SearchBar>
                    <CancelButton onClick={() => {
                        setSearch('');
                        setSearchBar(null);
                    }}>
                        Cancelar
                    </CancelButton>
                    <SearchButton onClick={() => {
                        searchLogs(backupTableData);
                        setSearchBar(null);
                    }}>
                        Buscar
                    </SearchButton>
                </SearchContainer>
            </Menu>
        </Container>
    );
}

export default ModalLogsUser;
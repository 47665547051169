import React, { useContext } from 'react';

import { Container, Content, ActionsContent, CancelButton, ConfirmButton, ModalConfirmHeader, ModalConfirmText, Divider } from './styles';

import deleteIcon from '../../assets/deleteIcon.svg'
import CustomLoading from '../CustomLoading';
import { MapsContext } from '../../contexts/MapsContext';

const ModalDeleteUser = ({ client, loading, onCancel, onSuccess }) => {

    const { removeFromMap } = useContext(MapsContext);

    const handleConfirm = async () => {
        const result = await removeFromMap(client);

        if(result){
            onSuccess()
        }else{
            onCancel()
        }        
    }

    return (
        <Container>
            <Content>
                <img src={deleteIcon} style={{ height: 80, width: 80 }} alt='map map'/>
                <ModalConfirmText>
                    Tem certeza que deseja excluir {client.nome} do mapa?
                </ModalConfirmText>
            </Content>
            <Divider />
            <ActionsContent>
                {loading
                    ? <CustomLoading />
                    : <>
                        <CancelButton onClick={onCancel}>
                            <p>Voltar</p>
                        </CancelButton>
                        <ConfirmButton onClick={() => handleConfirm()}>
                            <p>Excluir</p>
                        </ConfirmButton>
                    </>
                }
            </ActionsContent>
        </Container>
    )
}

export default ModalDeleteUser;
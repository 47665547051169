import React, { useContext, useState } from 'react'

import { Container, ContentHeader, List, TableColumns } from './styles';

import HistoryListItem from '../HistoryListItem'
import ModalViewClients from '../ModalViewClients';

import Modal from 'react-modal';
import { FiSearch } from 'react-icons/fi';
import { Menu } from '@mui/material';
import { CancelButton, ClearSearch, SearchBar, SearchButton, SearchContainer } from '../../../../components/CustomTable/styles';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { searchHelper } from '../../../../helpers/searchHelper';
import { MapsContext } from '../../../../contexts/MapsContext';
import { toast } from 'react-toastify';
import { alertSuccessStyle } from '../../../../resources/alertSuccessStyle';
import { alertErrorStyle } from '../../../../resources/alertErrorStyle';
import useImport from '../../../../hooks/useImport';
import { AuthContext } from '../../../../contexts/AuthContext';
import ModalConfirm from '../../../../components/ModalConfirm';

export default function HistoryList({ importHistory }) {

    const { userData } = useContext(AuthContext);
    const { maps } = useContext(MapsContext);
    const { undoImportClients } = useImport();

    const [ modalVisible, setModalVisible ] = useState(false);
    const [ modalUndo, setModalUndo ] = useState(false);

    const [ modalLoading, setModalLoading ] = useState(false);

    const [ selectedHistory, setSelectedHistory ] = useState('');

    const [ search, setSearch ] = useState('');
    const [ draftSearch, setDraftSearch ] = useState('');
    const [ searchBar, setSearchBar ] = useState(null);
    const openSearch = Boolean(searchBar);

    const handleView = async (data) => {
        setSelectedHistory(data)
        setModalVisible(true)
    }

    const searchHistoryList = (data) => {
        const arrayResult = [];

        if(search && importHistory){
            for(const item of importHistory){
                for(const client of item.importedClientsList){
                    if(searchHelper.searchText(client.cpf, search) || searchHelper.searchText(client.nome, search)){

                        const exists = arrayResult.find(x => x.docId === item.docId);

                        if(!exists){
                            arrayResult.push(item)
                        }   
                    }
                }

                for(const client of item.notImportedClientsList){
                    if(searchHelper.searchText(client.cpf, search) || searchHelper.searchText(client.nome, search)){
                        const exists = arrayResult.find(x => x.docId === item.docId);

                        if(!exists){
                            arrayResult.push(item)
                        } 
                    }
                }
            }
        }else{
            return data
        }
    
        return arrayResult
    }

    const clearOperation = () => {
        setModalLoading(false)
        setModalUndo(false)
        setSelectedHistory('')
    }

    const clearSearch = () => {
        setSearch('')
        setDraftSearch('')
    }

    const handleSearch = () => {
        setSearchBar(null)
        setSearch(draftSearch)
    }

    const handleUndoImport = async (item) => {
        var mapsClientsQtd = [];

        setModalLoading(true);

        if (item.importedClientsList && item.importedClientsList?.length > 0) {
            for (const client of item.importedClientsList) {
                if (client.cidadeMap) {
                    for (const map of maps) {
                        for (const city of map.cities) {
                            if (city.value == client.cidadeMap) {
                                client['mapId'] = map.docId
                                const index = mapsClientsQtd.findIndex(x => x.mapId === map.docId);

                                if (index == -1) {
                                    mapsClientsQtd.push({
                                        mapId: map.docId,
                                        mapName: map.mapName,
                                        qtd: 1
                                    })
                                } else {
                                    const array1 = mapsClientsQtd.slice(0, index);
                                    const array2 = mapsClientsQtd.slice(index + 1, mapsClientsQtd.length)

                                    array1.push({
                                        mapId: map.docId,
                                        mapName: map.mapName,
                                        qtd: mapsClientsQtd[index].qtd + 1
                                    })
                                    mapsClientsQtd = array1.concat(array2);
                                }
                            }
                        }
                    }
                }
            }

            const dataToSend = {
                historyDocId: item.docId,
                userName: userData.userName,
                mapsClientsQtd: mapsClientsQtd,
                clientsToUndo: item.importedClientsList,
                importTime: item.time,
            }

            const { success, error } = await undoImportClients(dataToSend)

            if (success) {
                toast(`${item.importedClientsList.length} clientes foram revertidos com sucesso`, alertSuccessStyle)
                clearOperation()
            } else {
                toast(`Erro ao reverter clientes do sistema: ${error}`, alertErrorStyle)
                clearOperation()
            }
        } else {
            toast('Nenhum cliente a ser revertido', alertErrorStyle)
            clearOperation()
        }
    }

    const listData = search ? searchHistoryList(importHistory) : importHistory

    return (
        <Container>
            <ContentHeader>
                <h4>Histórico de importações</h4>

                <div>
                    <FiSearch 
                        onClick={(event) => {
                            setSearchBar(event.currentTarget);
                        }}
                        size={20} 
                        cursor='pointer' 
                        color='var(--grey2)' 
                    />
                    <ClearSearch>
                        {draftSearch && (
                            <>
                                {draftSearch}
                                <AiOutlineCloseCircle cursor='pointer' onClick={() => clearSearch()} />
                            </>
                        )}
                    </ClearSearch>
                </div>
            </ContentHeader>
            <TableColumns>
                <h3>Administrador</h3>
                <h3>Status</h3>
                <h3>Data da importação</h3>
                <h3>Importados</h3>
                <h3>Não importados</h3>
                <h3>Total</h3>
                <h3>Ações</h3>
            </TableColumns>
            <List>
                {listData && listData.map((item, index) => (
                    <HistoryListItem 
                        key={index} 
                        data={item} 
                        onView={() => handleView(item)}
                        onUndo={() => {
                            setSelectedHistory(item)
                            setModalUndo(true)
                        }}
                    />
                ))}
            </List>
            <Modal
                isOpen={modalVisible}
                onRequestClose={() => {
                    setModalVisible(false);
                }}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalViewClients 
                    history={selectedHistory}
                    onClose={() => setModalVisible(false)}
                />
            </Modal>

            <Modal
                isOpen={modalUndo}
                onRequestClose={() => {
                    setModalUndo(false);
                }}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalConfirm 
                    loading={modalLoading}
                    data={{ 
                        text: "Tem certeza que deseja desfazer a importação de clientes?",
                        option: "Desfazer importação"
                    }}
                    onCancel={() => setModalUndo(false)}
                    onConfirm={() => handleUndoImport(selectedHistory)}
                />
            </Modal>

            <Menu
                id="basic-menu"
                anchorEl={searchBar}
                open={openSearch}
                onClose={() => {
                    setSearchBar(null);
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                PaperProps={{
                    style: {
                        width: 600,
                        height: 40,
                        maxHeight: 40,
                        borderRadius: 20,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        overflow: 'hidden',
                    },
                }}
            >
                <SearchContainer onSubmit={(e) => { 
                    e.preventDefault();
                    handleSearch()
                }}>
                    <FiSearch 
                        size={18} 
                        cursor='pointer' 
                        color='#626F87'
                        strokeWidth='3'
                    />
                     <p>&#8288;</p>
                    <SearchBar>
                        <input 
                            name="draftSearch" 
                            onChange={event => setDraftSearch(event.target.value)}
                            value={draftSearch}
                            placeholder='Buscar...'
                            autoFocus={true}
                        />
                    </SearchBar>
                    <CancelButton onClick={() => setSearchBar(null)}>
                        Cancelar
                    </CancelButton>
                    <SearchButton onClick={() => handleSearch()}>
                        Buscar
                    </SearchButton>
                </SearchContainer>
            </Menu>
        </Container>
    )
}

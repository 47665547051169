import React from 'react';

import { Container, Content, ActionsContent, CancelButton, ConfirmButton, ModalConfirmText, CloseButton, RightButtons } from './styles';

import { IoWarningOutline } from 'react-icons/io5';

const ModalConfirmImport = ({ data, onCancel, onClose, onConfirm }) => {

    return (
        <Container>
            <Content>
                <IoWarningOutline size={80}/>
                <ModalConfirmText>
                    {data.text}
                </ModalConfirmText>
            </Content>
            <ActionsContent>
                <CancelButton onClick={onCancel}>
                    <p>Cancelar</p>
                </CancelButton>
                <RightButtons>
                    <CloseButton onClick={onClose}>
                        <p>Não</p>
                    </CloseButton>
                    <ConfirmButton onClick={onConfirm}>
                        <p>{data.option}</p>
                    </ConfirmButton>
                </RightButtons>
            </ActionsContent>
        </Container>
    )
}

export default ModalConfirmImport;
import React, { useState, createContext } from "react";

export const HistoryContext = createContext();

export const HistoryProvider = (props) => {
    const { children } = props;

    const [ importHistory, setImportHistory ] = useState('');
    const [ removeHistory, setRemoveHistory ] = useState('');

    const clearHistoryContext = async () => {
        setImportHistory('')
        setRemoveHistory('');
        return
    }

    return (
        <HistoryContext.Provider 
            value={{ 
                importHistory: importHistory,
                removeHistory: removeHistory,
                setImportHistory,
                setRemoveHistory,
                clearHistoryContext,
            }}
        >
            {children}
        </HistoryContext.Provider>
    )
}
import React, { useContext, useState } from 'react';

import { 
    Circle,
    Container,
    Header,
    ListContainer,
    RankingHeader,
} from './styles';

//Icons
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { RiMedal2Line } from 'react-icons/ri'

//Custom components
import DownloadButton from '../DownloadButton';
import CustomLoading from '../CustomLoading';

//Utils
import Modal from 'react-modal';
import ModalDetailsAdmin from '../ModalDetailsAdmin';
import ModalDashboardItem from '../ModalDashboardItem';
import { UsersContext } from '../../contexts/UsersContext';

//MUI
import { Avatar } from '@mui/material';
import CustomNoRowsOverlay from '../CustomNoRowsOverlay';
import { avatar } from '../../helpers/avatar';
import CustomFilterHeader from '../CustomFilterHeader';
import { filtersHelper } from '../../helpers/filtersHelper';

function ModalDashboardCard({ data, onClose }) {

    const { users } = useContext(UsersContext)

    const [ currentClient, setCurrentClient ] = useState('');

    //filters
    const [ filters, setFilters ] = useState([])

    //Modal
    const [ modalFullData, setModalFullData ] = useState(false);

    const filterList = () => {
        return filtersHelper.filterDataTable(data.array, filters)
    }

    const clearFilters = () => {
        setFilters([])
    }

    if(!data){
        return (
            <Container>
                <CustomLoading />
            </Container>
        )
    }
    
    if(data.array.length == 0){
        return (
            <Container>
                <CustomNoRowsOverlay />
            </Container>
        )
    }

    const tableData = filters.length > 0 ? filterList() : data.array

    return (
        <Container>
            <Header>
                <h3>{data.title}</h3>

                    <div>
                    {data.filterTypes && (
                        <CustomFilterHeader
                            dateField='time'
                            filters={filters}
                            filterTypes={data.filterTypes}
                            tableRowsBackup={data.array}
                            onChangeFilters={(newFiltersArray) => {
                                setFilters(newFiltersArray)
                            }}
                            onClearFilters={() => clearFilters()}
                        />
                    )}
                    <DownloadButton tableColumns={data.tableColumns} tableRows={tableData} /> 
                    <AiOutlineCloseCircle style={{ marginLeft: 10 }} onClick={onClose} strokeWidth={0.5} cursor='pointer' size={22} />
                </div>
            </Header>
            {data.type && (
                <RankingHeader>
                    <Circle>
                        <RiMedal2Line color='white' />
                    </Circle>
                    {tableData.length > 0 && (
                        <Avatar
                            alt="Avatar"
                            src={avatar.getUserAvatar(users.operators, tableData[0].operatorUid)}
                            sx={{ 
                                width: 70, 
                                height: 70, 
                                borderRadius: 100, 
                                borderColor: 'white', 
                                borderWidth: 10 
                            }}
                        />
                    )}
                    
                    <h1>{tableData[0].operatorName}</h1>
                    <h3>{tableData.length} {data.type}</h3>
                </RankingHeader>
            )}
            <ListContainer height={data.type ? '55vh' : '75vh'}>
                {tableData && tableData.map((item, index) => (
                    <ModalDashboardItem 
                        key={index} 
                        data={item} 
                        type={data.type} 
                        columns={data.columns} 
                        onViewFullData={() => {
                            setCurrentClient(item);
                            setModalFullData(true);
                        }}
                    />
                ))}
            </ListContainer>

            <Modal
                isOpen={modalFullData}
                onRequestClose={() => setModalFullData(false)}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalDetailsAdmin 
                    data={currentClient} 
                    onClose={() => setModalFullData(false)}
                    onStreetView={() => {
                        
                    }}
                />
            </Modal>
        </Container>
    )
}

export default ModalDashboardCard;
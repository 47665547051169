import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    border-radius: 40px;
`;

export const RouteBar = styled.div`
    display: flex;
    flex-direction: column;
    width: 432px;
    height: 100%;

    padding: 20px 33px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;

    background: var(--white);
    box-shadow: 4px -2px 10px rgba(99, 99, 99, 0.15);
`;

export const Title = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 20px;

    color: #4A5E83;
`;

export const MapContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 40px;
`;

export const FieldText = styled.p`
    height: 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 24px;

    margin-bottom: 5px;

    color: #4A5E83;
`;

export const TextInput = styled.div`
    box-sizing: border-box;
    display: flex;

    width: 312px;
    height: 44px;
    align-items: center;

    border: 1px solid var(--grey1);
    border-radius: 100px;
`;

export const ActionButtons = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: right;

    gap: 30px;
    padding: 20px 10px;
`;

export const CancelButton = styled.div`
     display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    padding: 10px;

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        color: var(--grey2);
    }
`;


export const SaveButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    padding: 12px 58px 12px 58px;

    background: #DE6161;
    box-shadow: 0px 7px 10px rgba(228, 73, 73, 0.25);
    border-radius: 100px;

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        text-align: center;

        /* 5 */

        color: var(--white);

    }
`;

export const LogsButton = styled.div`
    display: flex;
    flex-direction: row;


    cursor: pointer;
    user-select: none;

    align-content: center;
    gap: 10px;
    padding: 10px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    /* DE6161 */

    color: #DE6161;
`;

export const CloseButton = styled.div`
    position: absolute;
    top: 2%;
    left: 94%;
    right: 0;
    bottom: 0;

    cursor: pointer;
    user-select: none;

    align-items: center;
    justify-content: center;

    color: var(--grey2);
`;

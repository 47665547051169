import React, { useContext } from "react";
import { Navigate } from 'react-router-dom';
import { AuthContext } from "../contexts/AuthContext";
import { ContainerLoading } from "../contexts/styles";
import { ClipLoader } from "react-spinners";

export const PrivateRoute = ({ children, redirectTo }) => {
    const { loading, user } = useContext(AuthContext); 

    if(!user || loading){
        return <ContainerLoading>
            <ClipLoader
                size={80}
                color="#DE6161"
                loading={true}
                speedMultiplier={1.5}
            />
        </ContainerLoading>
    }

    return user ? (
        children
    ) : (
        <Navigate to={redirectTo} replace/>
    )
}
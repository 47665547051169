import centralLogo from '../assets/centralLogo.png';
import { arrayHelper } from './arrayHelper';

export const avatar = {
    getUserAvatar,
    getAvatarByData
}

function getUserAvatar(users, uid){
    if(users && uid){
        const array = arrayHelper.reduceToSimple(users, 'uid');

        const user = users.find(x => x.uid == uid);

        if(user && user?.img64){
            return `data:image;base64,${user?.img64}`
        }
        
        return centralLogo
    }

    return centralLogo
}

function getAvatarByData(userData){
    if(userData && userData?.img64){
        return `data:image;base64,${userData?.img64}`
    }

    return centralLogo
}
import { useContext, useEffect, useState } from "react";
import { RoutesContext } from "../contexts/RoutesContext";

export default function useOperatorLocation(){
    const [ operatorsLocation, setOperatorsLocation ] = useState('');

    const { routes } = useContext(RoutesContext);

    useEffect(() => {
        if(routes){
            const array = []

            for(const route of routes.filter(x => x.status === 'iniciado')){
                if(route.operatorPosition && route.operatorPositionTime){
                    array.push({
                        lat: route.operatorPosition?.lat,
                        lng: route.operatorPosition.lng,
                        time: route.operatorPositionTime,
                        operatorName: route.operatorName,
                        operatorUid: route.uid
                    })
                }
            }

            setOperatorsLocation(array)
        }
    }, [routes])

    return {
        operatorsLocation: operatorsLocation,
        loading: !operatorsLocation ? true : false
    }
}
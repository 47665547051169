import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { UsersContext } from "../contexts/UsersContext";
import { mappers } from "../helpers/mappers";
import { collection, query, onSnapshot, where } from "firebase/firestore";
import db from '../firebase/config';
import { arrayHelper } from "../helpers/arrayHelper";
import { RoutesContext } from "../contexts/RoutesContext";

export default function useRoutes(){
    const { isAdmin, isManager, user } = useContext(AuthContext);

    const { routes, setRoutes, routesUser, setRoutesUser } = useContext(RoutesContext);

    const { users } = useContext(UsersContext);

    //Get routes admin
    const getRoutesAdmin = async () => {
        const consulta = query(collection(db, 'rotas'));
        await onSnapshot(consulta, (querySnapshot) => {
            
            const mappedDocs = mappers.mapFirebaseDoc(querySnapshot)
            
            setRoutes(mappedDocs);
        }, error => {
            console.log("getRoutesAdmin error", error)
        });
    }

    //Get routes manager
    const getRoutesManager = async (operatorsManager) => {

        if(operatorsManager.length == 0){
            return setRoutes([]);
        }

        const operatorsUid = arrayHelper.reduceToSimple(operatorsManager, 'uid');

        const consulta = query(collection(db, 'rotas'), where('uid', 'in', operatorsUid));
        onSnapshot(consulta, (querySnapshot) => {
            const mappedDocs = mappers.mapFirebaseDoc(querySnapshot)
        
            setRoutes(mappedDocs);
        }, error => console.log("getRoutesManager error", error));
    }

    const getRoutesUser = async (user) => {
        const consulta = query(collection(db, 'rotas'), where("uid", "==", user.uid), where("status", "in", ["aguardando", "iniciado"]));
        await onSnapshot(consulta, (querySnapshot) => {
            const mappedDocs = mappers.mapFirebaseDoc(querySnapshot)
            
            setRoutesUser(mappedDocs); 
        });                  
    }

    const fetchRoutes = () => {
        if(isAdmin){
            if(!routes){
                getRoutesAdmin()
            }
        }
    
        if(isManager){
            if(users && !routes){
                getRoutesManager(users.operators);
            }
        }

        if(!isAdmin && !isManager){
            if(!routesUser && user){
                getRoutesUser(user);
            }
        }
    }

    return {
        routes: routes,
        routesUser: routesUser,
        fetchRoutes,
        loading: !routes ? true : false
    }
}
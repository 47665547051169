import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr) repeat(3, 0.5fr) 0.5fr;

    padding: 0 15px;
    cursor: pointer;
    user-select: none;

    align-items: center;
    min-height: 59px;

    div {
        display: flex;
        flex-direction: row;
        align-items: center;

        gap: 10px
    }

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        color: #808B9F;
    }
`;

export const TextResult = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: ${props => props.color};
`;

export const Status = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 2px 10px;
    background: ${props => props.undo ? 'var(--yellow)' : 'var(--success)'};
    border-radius: 100px;

    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: var(--white);
`;

import React from 'react';

import { Container } from './styles';

import { BiDownload } from 'react-icons/bi';

//utils
import { datetime } from '../../helpers/datetime';
import XLSX from 'sheetjs-style'
import { reportResultType } from '../../constants/reportResultType';

function DownloadButton({ tableColumns, tableRows }) {

    const csvMaker = function (data) {
        const csvRows = [];
        const header = [];
        const fields = [];

        for(const column of tableColumns){
            if(column.field != 'actions'){
                header.push(column.headerName);
                fields.push(column.field);
            }
        }
        csvRows.push(header.join(';'));
        
        for(const item of data){
            var obj = {};

            for(const field of fields){
                const result = tableColumns.find(x => x.field == field);

                if(result && result.headerName == 'Data'){
                    obj[field] = datetime.getFullDate(item[field]);
                }else{
                    obj[field] = item[field];
                }
            }

            const values = Object.values(obj).join(';');

            csvRows.push(values)
        }

        var BOM = "\uFEFF";
        var    csvData = BOM + csvRows.join('\n');
        const blob = new Blob([csvData], { type: 'application/octet-binary' });
        const url = window.URL.createObjectURL(blob)
    
        const a = document.createElement('a')
        a.setAttribute('href', url)

        a.setAttribute('download', 'download.xls');
        a.click()
    }

    const xlsMaker = async () => {
        const xlsxRows = [];
        const header = [];
        const fields = [];

        const array = tableColumns.slice(0, tableColumns.length-1)
        const newTableColumns = array.concat([{ field: 'bairro', headerName: 'Bairro' }])
            .concat(tableColumns[tableColumns.length-1])

        for(const column of newTableColumns){
            if(column.field != 'actions'){
                header.push(column.headerName);
                fields.push(column.field);
            }
        }

        for(const item of tableRows){
            var obj = {};

            for(const field of fields){
                const result = newTableColumns.find(x => x.field == field);

                if(result && result.headerName){
                    if(result.headerName == 'Data'){
                        obj[result.headerName] = datetime.getFullDate(item[field]);
                    }else{
                        if(result.headerName === 'Cidade' && item['result'] === reportResultType.RECOMMENDATION_SIGNED){
                            obj[result.headerName] = item['clientCidade'];
                        }else if(result.headerName === 'Bairro' && item['result'] === reportResultType.RECOMMENDATION_SIGNED){
                            obj[result.headerName] = item['clientBairro'];
                        }else{
                            obj[result.headerName] = item[field];
                        }
                    }
                }
            }

            xlsxRows.push(obj)
        }

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        const ws = XLSX.utils.json_to_sheet(xlsxRows);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType })

        const url = window.URL.createObjectURL(data)
    
        const a = document.createElement('a')
        a.setAttribute('href', url)

        a.setAttribute('download', 'download.xlsx');
        a.click()
    }

    return (
        <Container onClick={() => xlsMaker()}>
            <BiDownload style={{ cursor: 'pointer' }} size={18} color='#FFF'  fill='#FFF' />
        </Container>
    )
}

export default DownloadButton;
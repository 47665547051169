import React, { useContext, useState } from 'react';

//Firebase and Context
import { AuthContext } from '../../contexts/AuthContext';

//Styles
import { 
    ActionsContainer, 
    Container, 
    ExpandedButton, 
    MapDetails, 
    MapHeader, 
    MapName, 
    CitiesContainer,
    CityItem
} from './styles';

//Custom components
import ModalLogsMaps from '../ModalLogsMaps';

//Icons
import { TbPencil } from 'react-icons/tb';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { FiTrash2 } from 'react-icons/fi';
import ElipseIcon from '../ElipseIcon';
import logIconGrey from '../../assets/logIconGrey.svg';

//Utils
import Modal from 'react-modal'
import { markerIcons } from '../../resources/markerIcons';

const exceptionIcons = ['BIRTHDAY', 'RETORNO', 'RETORNO PREMIUM']

const AdminMapCard = ({ map, onEdit, onDelete, selected, onSelect }) => {

    const { isAdmin } = useContext(AuthContext);

    const [ expanded, setExpanded ] = useState(false);

    const [ modalLogs, setModalLogs ] = useState(false);

    const calculateEspecie = (especie) => {
        const result = map.clientes.filter(x => x.especie === especie)
        
        return result.length;
    }

    return (
        <Container selected={selected} onClick={onSelect}>
            <MapHeader>
                <MapName>
                    <p>{map.mapName}</p>
                    <ElipseIcon 
                        text={map.clientes.length} 
                        size={17} 
                        background='var(--blue3)' 
                        fontWeight='400' 
                        fontSize={12} 
                    />
                </MapName>
                {map.mapName != 'Geral' && isAdmin && (
                    <ActionsContainer>
                        <img src={logIconGrey} alt='img img' style={{ cursor: 'pointer'}} onClick={() => setModalLogs(true)}/>
                        <TbPencil 
                            onClick={onEdit}
                            size={22} 
                            style={{ strokeWidth: 1.5, cursor: 'pointer' }} 
                        />
                        <FiTrash2 
                            onClick={onDelete}
                            color='var(--red)' 
                            size={20} 
                            style={{ strokeWidth: 1.5, cursor: 'pointer' }}
                        />
                    </ActionsContainer>
                )}
            </MapHeader>
            <MapDetails>
                {markerIcons && markerIcons.map((item, index) => {
                    const findException = exceptionIcons.find(x => x === item.especie);
                    const value = calculateEspecie(item.especie);

                    if(!findException && value > 0){
                        return (
                            <div key={index}>
                                <img src={item.icon} alt='img img' style={{ height: 14, width: 14 }} />
                                <h4>{value}</h4>
                            </div>
                        )
                    }
                })}
            </MapDetails>
            {expanded && (<>
                <CitiesContainer>
                    {map.cities.length != 0 && map.cities.map((item, index) => (
                        <CityItem key={index}>
                            {item.label}
                        </CityItem>
                    ))}
                </CitiesContainer >
            </>)}
            <ExpandedButton onClick={() => {
                    setExpanded(!expanded)
                }}>
                <p>{expanded ? 'Recolher' : 'Expandir' }</p>
                {expanded
                    ? <IoIosArrowUp />
                    : <IoIosArrowDown  />
                }
            </ExpandedButton>

            <Modal
                isOpen={modalLogs}
                onRequestClose={() => {
                    setModalLogs(false);
                }}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalLogsMaps 
                    mapId={map.docId}
                    mapName={map.mapName}
                    onClose={() => {
                        setModalLogs(false);
                    }}
                />
            </Modal>
        </Container>
    )
}

export default AdminMapCard;
import React, { useEffect, useState, useContext, useRef } from "react";
import { 
    Container, 
    UserCard, 
    UserName, 
    UsersCardsContainer,
    Header,
    UserCardHeader,
    UserCardContent,
    FieldText,
    UserCardText,
    NewUserButton,
    ConfigButton,
    SwitchType,
    SwitchButton,
    EspeciesContent,
    EspeciesSelectorItem,
    EspeciesItem,
    EspeciesSelector,
    SaveEspeciesButton,
    EspeciesHeader,
    AdminCardContainer
} from "./styles";

//Firebase and context
import { AuthContext } from "../../contexts/AuthContext";
import { MapsContext } from "../../contexts/MapsContext";
import { getAuth } from "firebase/auth";
import { setDoc, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import db from './../../firebase/config';

//Icons
import { TbPencil } from 'react-icons/tb';
import { FiTrash2 } from 'react-icons/fi';
import { AiOutlineEyeInvisible, AiOutlineEye, AiOutlineCloseSquare } from 'react-icons/ai';
import { FiCheckSquare } from 'react-icons/fi';
import { IoIosArrowDown, IoIosArrowUp, IoMdAdd } from 'react-icons/io';
import { HiOutlineCog } from 'react-icons/hi';
import logIconGrey from '../../assets/logIconGrey.svg'

//Custom components
import ModalLogsUser from "../../components/ModalLogsUser";
import ProfileButton from "../../components/ProfileButton";
import ModalFormUser from "../../components/ModalFormUser";
import ModalConfirm from '../../components/ModalConfirm';
import CustomLoadingPage from "../../components/CustomLoadingPage";

//MUI
import Avatar from '@mui/material/Avatar';

//Utils
import { toast } from 'react-toastify';
import Select from 'react-select';
import Modal from 'react-modal';
import { UsersContext } from "../../contexts/UsersContext";
import { USER_TYPE } from "../../constants/userType";
import { addUserLogs } from "../../firebase/logs/users/addUserLogs";
import { userRoleOptions } from "../../constants/userRoleOptions";
import { alertErrorStyle } from "../../resources/alertErrorStyle";
import { alertSuccessStyle } from "../../resources/alertSuccessStyle";
import { especiesFilterIcons } from '../../resources/especiesFilterIcons';
import { arrayHelper } from "../../helpers/arrayHelper";
import _ from "lodash";
import { ClipLoader } from "react-spinners";

const ManageUsersPage = () => {

    const { user, userData } = useContext(AuthContext);
    const { users, getUsersAdmin, updateRoleToAdmin, updateRoleToManager, 
        updateRoleToOperator, updateManagerMapsIds } = useContext(UsersContext);
    const { maps } = useContext(MapsContext);
    
    const auth = getAuth();
    
    const [ loadingData, setLoadingData ] = useState(false);
    const [ especiesSaveLoading, setEspeciesSaveLoading ] = useState(false);

    //User manager
    const [ selectedUser, setSelectedUser ] = useState('');
    const [ usersArray, setUsersArray ] = useState([]);
    const [ mapOptions, setMapOptions ] = useState([]);
    const [ operatorsOptions, setOperatorsOptions ] = useState([]);
    const [ showPasswordUser, setShowPasswordUser ] = useState('');
    const [ selectedType, setSelectedType ] = useState(USER_TYPE.OPERATOR)

    //Modal
    const [ modalData, setModalData ] = useState('');
    const [ modalConfirm, setModalConfirm ] = useState(false);
    const [ modalLoading, setModalLoading ] = useState(false);
    const [ modalEditUser, setModalEditUser ] = useState(false);
    const [ modalLogs, setModalLogs ] = useState(false);
    const [ especiesExpanded, setEspeciesExpanded ] = useState('');

    useEffect(() => {
        setLoadingData(true);

        if(users.allUsers && users.managers && maps){
            const database = [];
            const databaseOptions = [];
            const allOperators = [];

            for(const adminUser of users.admins){
                const result = users.allUsers.find(x => x.uid == adminUser.uid);

                if(result){
                    database.push({
                        ...result,
                        type: USER_TYPE.ADMIN,
                    })
                }
            }

            for(const managerUser of users.managers){
                const newOperatorsArray = []
                const result = users.allUsers.find(x => x.uid == managerUser.uid);
    
                for(const operatorUid of managerUser.operatorsArray){
                    allOperators.push(operatorUid)

                    const result = users.allUsers.find(x => x.uid == operatorUid);

                    if(result){
                        newOperatorsArray.push({ label: result.userName, value: operatorUid})
                    }
                }

                database.push({
                    ...result,
                    type: USER_TYPE.MANAGER,
                    operatorsArray: newOperatorsArray,
                })
            }

            for(const operatorUser of users.operators){
                const result = users.allUsers.find(x => x.uid == operatorUser.uid);

                if(result){
                    database.push({
                        ...result,
                        type: USER_TYPE.OPERATOR,
                    })
                }
            }

            //get valid operator to managers
            for(const user of database){
                const result = allOperators.find(x => x === user.uid);

                if(!result){
                    if(user.type == USER_TYPE.OPERATOR){
                        databaseOptions.push({ label: user.userName, value: user.uid })
                    }
                }
            }
            setUsersArray(database)
            setOperatorsOptions(databaseOptions);
        }

        const arrayOptions = [];

        if(maps){
            for(const map of maps){
                arrayOptions.push({
                    label: map.mapName,
                    value: map.docId,
                })
            }
        }
        
        setMapOptions(arrayOptions);
        setLoadingData(false);
    }, [maps, users]);

    const filteredUsers = selectedType != null ? usersArray.filter(x => x.type == selectedType) : usersArray;

    const tryCreateUser = async (newUser) => {
        const date = new Date();
        const created = `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`;

        if(!newUser.email || !newUser.password || !newUser.userName || !newUser.type){
            setModalLoading(false);
            return toast(`Preencha os campos Nome, Email, Senha e Tipo para continuar`, alertErrorStyle)
        }

        await auth.currentUser.getIdToken(/* forceRefresh */ true).then(async function(idToken) {
            const functions = getFunctions();
            const createUser = httpsCallable(functions, 'createUser');
            await createUser({idToken: idToken, email: newUser.email, password: newUser.password, role: newUser.type})
            .then(async result => {
                const data = result.data;

                if(!data.success){
                    return toast(data.error, alertErrorStyle)
                }
                
                if(data.success){
                    const docRef = doc(db, "users", data.uid);

                    const newData = {
                        userName: newUser.userName,
                        email: newUser.email,
                        login: newUser.login ? newUser.login : '',
                        password: newUser.password,
                        img64: newUser.img64 ? newUser.img64 : '',
                        created: created,
                        lastRoute: [],
                        mapsIds: [],
                    }

                    await setDoc(docRef, newData).then(async () => {
                        const logMessage = `${newUser.userName} foi criado pelo admin`

                        await addUserLogs(data.uid, logMessage)

                        toast(logMessage, alertSuccessStyle)
                    }).catch(async (error) => {
                        const logMessage = `Erro ao criar ${newUser.userName}: ${error.code}`

                        await addUserLogs(data.uid, logMessage)

                        return toast(`${newUser.userName} erro ao criar usuário usuário: ${error.code}`, alertErrorStyle)
                    })
                }
            })
        }).catch(function(error) {
            return toast(`${newUser.userName} erro ao criar usuário usuário: ${error.code}`, alertErrorStyle)
        });

        getUsersAdmin(user);
        setModalLoading(false);
        setSelectedUser('')
        setModalEditUser(false);
        window.location.reload();
    }

    const tryResetPassword = async (newUser) => {
        var resposta = '';

        await auth.currentUser.getIdToken(/* forceRefresh */ true).then(async function(idToken) {
            const functions = getFunctions();
            const resetPassword = httpsCallable(functions, 'resetPassword');
            await resetPassword({idToken: idToken, uid: newUser.uid, password: newUser.password}).then(result => {
                if(result.data === 'Done'){
                    toast("Senha alterada com sucesso", alertSuccessStyle)
                    resposta = true
                }else{
                    toast(`Erro desconhecido ao alterar senha`, alertErrorStyle)
                    resposta = false
                }
            })
        }).catch(function(error) {
            toast(`Ocorreu um erro ao alterar a senha: ${error.code}`, alertErrorStyle)
            resposta = false
        });    

        return resposta;
    }

    const tryChangeRole = async(oldRole, newRole) => {
        let result = false;

        if(newRole == USER_TYPE.ADMIN){
            result = await updateRoleToAdmin(selectedUser, oldRole);
        }

        if(newRole == USER_TYPE.MANAGER){
            result = await updateRoleToManager(selectedUser, oldRole);
        }

        if(newRole == USER_TYPE.OPERATOR){
            result = await updateRoleToOperator(selectedUser, oldRole);
        }

        if(!result){
            setModalLoading(false);
            return
        }
        return 
    }

    const tryDisableUser = async (uid, newState) => {
        await auth.currentUser.getIdToken(/* forceRefresh */ true).then(async function(idToken) {
            const functions = getFunctions();
            const setOperator = httpsCallable(functions, 'disableAccount');
            await setOperator({idToken: idToken, uid: uid, newState: newState}).then(async result => {
                if(result.data === 'Done'){

                 

                    const docRef = doc(db, "users", selectedUser.uid);

                    await updateDoc(docRef, {
                        disabled: newState
                    }).then(async () => {
                        const logMessage = `${selectedUser.userName} teve seu login ${newState == true ? 'desativado' : 'reativado'}`

                        await addUserLogs(uid, logMessage)

                        toast(logMessage, alertSuccessStyle)
                    }).catch(async (error) => {
                        const logMessage = `Erro ao ${newState == true ? 'desativar' : 'reativar'} ${selectedUser.userName}: ${error.code}`

                        await addUserLogs(uid, logMessage)
                        setModalLoading(false);
                        return toast(logMessage, alertErrorStyle)
                    })
                }else{
                    const logMessage = `Erro desconhecido ao ${newState == true ? 'desativar' : 'reativar'} ${selectedUser.userName}`

                    await addUserLogs(uid, logMessage)
                    setModalLoading(false);
                    return toast(logMessage, alertErrorStyle)
                }
            })
        }).catch(async (error) => {
            const logMessage = `Erro ao ${newState == true ? 'desativar' : 'reativar'} ${selectedUser.userName}: ${error.code}`

            await addUserLogs(uid, logMessage)
            setModalLoading(false);
            return toast(logMessage, { 
                style: {
                    background: 'var(--red)',
                    color: 'white',
                },
                progressStyle: {
                    background: 'white'
                },
                icon: <AiOutlineCloseSquare />
            })
        }); 

        setModalLoading(false);
        setSelectedUser('')
        setModalEditUser(false)
        window.location.reload();
    }

    const trySaveUser = async (newUser) => {
        const docRef = doc(db, "users", selectedUser.uid);

        var statusPassword = false;

        if(newUser.password != selectedUser.password){
            const result = await tryResetPassword(newUser);

            statusPassword = result 
        }

        await updateDoc(docRef, {
            login: newUser.login ? newUser.login : '',
            userName: newUser.userName,
            password: statusPassword == true ? newUser.password : selectedUser.password,
            img64: newUser.img64 
                ? newUser.img64 
                : selectedUser.img64
                    ? selectedUser.img64
                    : '' 
        }).then(async () => {
            const logMessage = `${newUser.userName} foi editado pelo admin`

            await addUserLogs(selectedUser.uid, logMessage)

            toast(logMessage, alertSuccessStyle)
        }).catch(async (error) => {
            const logMessage = `Erro ao editar ${newUser.userName}: ${error.code}`

            await addUserLogs(selectedUser.uid, logMessage)

            if(error.code == 'invalid-argument'){
                return toast('Essa imagem é muito pesada para ser enviada, tente enviá-la pelo whatsapp ao computador.', alertErrorStyle)
            }else{
                return toast(`${selectedUser.userName} erro ao salvar usuário: ${error.code}`, alertErrorStyle)
            }
        })

        //Não é gerente e será gerente
        if(newUser.type != selectedUser.type){
            await tryChangeRole(selectedUser.type, newUser.type)
        }

        if(newUser.disabled != selectedUser.disabled){
            await tryDisableUser(selectedUser.uid, newUser.disabled)
        }

        getUsersAdmin(user);
        setModalLoading(false);
        setSelectedUser('');
        setModalEditUser(false);
        window.location.reload();
        return
    }

    const tryDeleteUser = async () => {
        await deleteDoc(doc(db, "users", selectedUser.uid))
            .then(async () => {
                if(selectedUser.type === 'manager'){
                    await deleteDoc(doc(db, "managers", selectedUser.uid))
                        .then(() => {
                             toast(`Usuário ${selectedUser.userName} excluído!`, alertSuccessStyle)
                        })
                        .catch((error) => {
                            setModalLoading(false);
                            return toast(`Erro ao excluir usuário ${selectedUser.userName}: ${error.code}`, alertErrorStyle)
                        })
                }
        
                if(selectedUser.type === 'admin'){
                    await deleteDoc(doc(db, "admins", selectedUser.uid))
                        .then(() => {
                             toast(`Usuário ${selectedUser.userName} excluído!`, alertSuccessStyle)
                        })
                        .catch((error) => {
                            setModalLoading(false);
                            return toast(`Erro ao excluir usuário ${selectedUser.userName}: ${error.code}`, alertErrorStyle)
                        })
                }

                if(selectedUser.type === 'operator'){
                    toast(`Usuário ${selectedUser.userName} excluído!`, alertSuccessStyle)
                }
            })
            .catch((error) => {
                setModalLoading(false);
                return toast(`Erro ao excluir usuário ${selectedUser.userName}: ${error.code}`, alertErrorStyle)
            })
        
        

        getUsersAdmin(user)
        setSelectedUser('');
        setModalLoading(false);
        setModalConfirm(false)
        window.location.reload();
    }

    const changeOperators = async (operatorsArray, item) => {
        const docRef = doc(db, "managers", item.uid);

        const newOperatorsArray = [];
        const newMapArray = [];


        for(const item of operatorsArray){
            newOperatorsArray.push(item.value);
            const data = usersArray.find(x => x.uid == item.value);

            if(data){
                for(const itemMapId of data.mapsIds){
                    const result = newMapArray.find(x => x == itemMapId.value);

                    if(!result){
                        newMapArray.push(itemMapId.value);
                    }
                }
                
            }            
        }
        
        await updateDoc(docRef, {
            operatorsArray: newOperatorsArray,
            mapsArray: newMapArray,
        }).then(async () => {
            const logMessage = `${item.userName} teve seus operadores alterados`

            await addUserLogs(item.uid, logMessage)
            toast(logMessage, alertSuccessStyle)
        }).catch(async (error) => {
            const logMessage = `Erro ao atualizar operadores do gerente ${item.userName}: ${error.code}`

            await addUserLogs(item.uid, logMessage)
            toast(logMessage, alertErrorStyle)
        })

        getUsersAdmin(user)
    }

    const changeMap = async (mapsArray, item) => {      
        
        const { status, error } = await updateManagerMapsIds(item, mapsArray);

        if(!status){
            const logMessage = `Erro ao atualizar o mapa do operador ${item.userName}: ${error.code}`

            await addUserLogs(item.uid, logMessage)
            return toast(logMessage, alertErrorStyle)
        }
        
        const docRef = doc(db, "users", item.uid);

        var stringChanges = ''
        const mapNames = []
        var especies = {}

        for(const map of mapsArray){
            mapNames.push(map.label);

            if(stringChanges){
                stringChanges = stringChanges + ' | ' + map.label
            }else{
                stringChanges = stringChanges + map.label
            }
        }

        if(item.especies){
            const keys = Object.keys(item.especies);

            for(const mapName of mapNames){
                const exists = keys.find(x => x === mapName);

                if(exists){
                    especies[mapName] = item.especies[mapName]
                }else{
                    especies[mapName] = arrayHelper.reduceToSimple(especiesFilterIcons, 'especie')
                }
            }
        }else{
            for(const mapName of mapNames){
                especies[mapName] = arrayHelper.reduceToSimple(especiesFilterIcons, 'especie')
            }
        }

        await updateDoc(docRef, {
            mapsIds: mapsArray,
            especies: especies
        }).then(async () => {
            const logMessage = `${item.userName} teve seu mapa alterado para: ${stringChanges}`

            await addUserLogs(item.uid, logMessage)
            toast(logMessage, alertSuccessStyle)
        }).catch(async (error) => {
            const logMessage = `Erro ao atualizar o mapa do operador ${item.userName}: ${error.code}`

            await addUserLogs(item.uid, logMessage)
            toast(logMessage, alertErrorStyle)
        })
        
        getUsersAdmin(user)
    }

    const getEspeciesByMaps = (operator) => {
        const array = [];

        if(operator.especies){
            const keys = Object.keys(operator.especies);

            for(const mapName of keys){
                array.push({
                    mapName: mapName,
                    especiesArray: operator.especies[mapName]
                })
            }
        }

        return array
    }

    const changeEspecie = (item, mapName, operator) => {
        var newArray = []

        const index = operator.especies[mapName].findIndex(x => x == item.especie);

        if(index != -1){    
            newArray = arrayHelper.removeItemOfArray(operator.especies[mapName], index)
        }else{
            newArray = operator.especies[mapName].concat([item.especie]);
        }
    
        const operatorIndex = usersArray.findIndex(x => x.uid == operator.uid);

        const array1 = usersArray.slice(0, operatorIndex);
        const array2 = usersArray.slice(operatorIndex+1, usersArray.length);

        if(operatorIndex != -1){    
            array1.push({
                ...operator,
                especies: {
                    ...operator.especies,
                    [mapName]: newArray
                }
            })
    
            setUsersArray(array1.concat(array2))
        }        
    }

    const saveChangedEspecies = async (operatorData) => {
        setEspeciesSaveLoading(true)
        const docRef = doc(db, "users", operatorData.uid);

        await updateDoc(docRef, {
            especies: operatorData.especies,
        }).then(async () => {
            const logMessage = `${operatorData.userName} teve seus benefícios visíveis no mapa alterados`

            await addUserLogs(operatorData.uid, logMessage)
            toast(logMessage, alertSuccessStyle)
        }).catch(async (error) => {
            const logMessage = `Erro ao atualizar benefícios visíveis no mapa do operador ${operatorData.userName}: ${error.code}`

            await addUserLogs(operatorData.uid, logMessage)
            toast(logMessage, alertErrorStyle)
        })
        
        await getUsersAdmin(user)
        setEspeciesSaveLoading(false)
    }

    const handleEspeciesExpanded = (operator) => {
        if(especiesExpanded == operator.uid){
            setEspeciesExpanded('')
        }else{
            setEspeciesExpanded(operator.uid)
        }
    }

    const verifyEspeciesChanges = (operator) => {
        const result = users.allUsers.find(x => x.uid == operator.uid);

        if(result){
            if(result.especies && operator.especies){
                return !_.isEqual(result.especies, operator.especies);
            }

            if(!result.especies){
                return !_.isEqual(result.especies, operator.especies);
            }
        }
    }

    const customStyle = {      
        container: (base) => ({
            ...base,
            borderRadius: 40,
            marginBottom: 10
        }),
        control: (base) => ({
            ...base,
            padding: 3,
            borderRadius: 40            
        }),
        multiValue: (base) => ({
            ...base,
            borderRadius: 40,
            backgroundColor: '#ECF2FB'
        }),
    } 

    if(loadingData){
        return <Container>
            <CustomLoadingPage />
        </Container>
    }

    return (
        <Container>
            <Header>
                <h1>Usuários</h1>
                <div>
                    <NewUserButton onClick={() => {
                        setSelectedUser('');
                        setModalEditUser(true)
                    }}>
                        <IoMdAdd />
                        <h3>Novo usuário</h3>
                    </NewUserButton>
                    <ProfileButton arrowColor='var(grey2)' />
                </div>
            </Header>

            <AdminCardContainer>
                <UserCard>
                    <UserCardHeader>
                        <div>
                            <Avatar
                                alt="Remy Sharp"
                                src={`data:image;base64,${userData.img64}`}
                                sx={{ width: 58, height: 58, cursor: 'pointer' }}
                                onClick={() => {
                                    setSelectedUser({...userData, uid: user.uid});
                                }}
                            />
                            <UserName>
                                <h3>{userData.userName}</h3>
                            </UserName>
                        </div>
                    </UserCardHeader>
                </UserCard>
            </AdminCardContainer>

            <br />
            <br />

            <SwitchType>
                <SwitchButton onClick={() => setSelectedType(USER_TYPE.OPERATOR)}>
                    Operadores
                    {selectedType == USER_TYPE.OPERATOR && (<div></div>)}
                </SwitchButton>
                <SwitchButton onClick={() => setSelectedType(USER_TYPE.MANAGER)}>
                    Gerentes
                    {selectedType == USER_TYPE.MANAGER && (<div></div>)}
                </SwitchButton>
                <SwitchButton onClick={() => setSelectedType(USER_TYPE.ADMIN)}>
                    Administradores
                    {selectedType == USER_TYPE.ADMIN && (<div></div>)}
                </SwitchButton>
                <SwitchButton onClick={() => setSelectedType(null)}>
                    Todos
                    {selectedType == null && (<div></div>)}
                </SwitchButton>
            </SwitchType>

            <UsersCardsContainer>
                {filteredUsers && filteredUsers.map((item, index) => (
                    <UserCard key={index}>
                        <UserCardHeader>
                            <div>
                                <Avatar
                                    alt="Remy Sharp"
                                    src={`data:image;base64,${item.img64}`}
                                    sx={{ width: 58, height: 58, cursor: 'pointer' }}
                                    onClick={() => {
                                        setSelectedUser(item);
                                        setModalEditUser(true)
                                    }}
                                />
                                <UserName>
                                    <h3>{item.userName}</h3>
                                    <h4>{userRoleOptions.find(x => x.value == item.type).label}</h4>
                                </UserName>
                            </div>
                            <div>
                                <TbPencil 
                                    size={22} 
                                    style={{ strokeWidth: 1.5, cursor: 'pointer' }} 
                                    onClick={() => {
                                        setSelectedUser(item);
                                        setModalEditUser(true)
                                    }}
                                />
                                <FiTrash2 
                                    color='var(--red)' 
                                    size={20} 
                                    style={{ strokeWidth: 1.5, cursor: 'pointer' }}
                                    onClick={() => {
                                        setSelectedUser(item);
                                        setModalData({
                                            text: `Tem certeza que deseja excluir ${item.userName}?`,
                                            option: 'Excluir'
                                        })
                                        setModalConfirm(true);
                                    }}
                                />
                            </div>
                        </UserCardHeader>
                        <UserCardContent>
                            {item.type == USER_TYPE.MANAGER && (
                                <>
                                    <FieldText>
                                        Seleção de operadores:
                                    </FieldText>
                                    <Select 
                                        id='select-manager'
                                        key={`select-operator-${item.uid}`}
                                        options={operatorsOptions} 
                                        isMulti 
                                        defaultValue={item.operatorsArray} 
                                        styles={customStyle}
                                        onChange={(selectedOption) => {
                                            changeOperators(selectedOption, item);
                                        }}
                                    />
                                </>
                            )}
                            {item.type == USER_TYPE.OPERATOR && (
                                <>
                                    <FieldText>
                                        Seleção de mapa:
                                    </FieldText>
                                    <Select 
                                        id='select-map'
                                        key={`select-map-${item.uid}`}
                                        options={mapOptions} 
                                        isMulti 
                                        defaultValue={item.mapsIds} 
                                        styles={customStyle}
                                        onChange={(selectedOption) => {
                                            changeMap(selectedOption, item);
                                        }}
                                    />

                                    <EspeciesHeader onClick={() => handleEspeciesExpanded(item)}>
                                        <FieldText>
                                            Seleção de benefícios:
                                        </FieldText>
                                        {especiesExpanded == item.uid
                                            ? <IoIosArrowUp cursor='pointer' />
                                            : <IoIosArrowDown cursor='pointer' />
                                        }
                                    </EspeciesHeader>
                                    
                                    {especiesExpanded == item.uid && (
                                        <EspeciesContent>
                                            {getEspeciesByMaps(item).length == 0 && (
                                                <h4>Nenhum mapa selecionado</h4>
                                            )}
                                            {getEspeciesByMaps(item).map((itemEspecie, index) => (
                                                <EspeciesItem key={index}>
                                                    <h3>{itemEspecie.mapName}</h3>
                                                    <EspeciesSelector>
                                                        {especiesFilterIcons.map((especie, index) => (
                                                            <EspeciesSelectorItem 
                                                                key={index} 
                                                                title={especie.title}
                                                                selected={arrayHelper.findInArray(itemEspecie.especiesArray, especie.especie)}
                                                                onClick={() => changeEspecie(especie, itemEspecie.mapName, item)}
                                                            >
                                                                <img src={especie.icon} />
                                                            </EspeciesSelectorItem>
                                                        ))}
                                                    </EspeciesSelector>
                                                </EspeciesItem>
                                            ))}
                                            {verifyEspeciesChanges(item) && (
                                                <SaveEspeciesButton onClick={() => saveChangedEspecies(item)}>
                                                    {especiesSaveLoading 
                                                        ? <ClipLoader 
                                                            color="var(--white)" 
                                                            size={30} 
                                                            loading={true} 
                                                            speedMultiplier={1.5} 
                                                        />
                                                        : <h3>Salvar benefícios</h3>
                                                    }
                                                </SaveEspeciesButton>
                                            )}
                                        </EspeciesContent>
                                    )}
                                </>
                            )}
                            <UserCardText>
                                <div>
                                    <span>E-mail:</span>
                                    <h4>{item.email}</h4>
                                </div>
                            </UserCardText>
                            <UserCardText>
                                <div>
                                    <span>Login:</span>
                                    <h4>{item.login ? item.login : 'Não definido'}</h4>
                                </div>
                            </UserCardText>
                            <UserCardText>
                                <div>
                                    <span>Senha:</span>
                                    <h4>{item.password 
                                        ? showPasswordUser === item.uid 
                                            ? item.password 
                                            : '******' 
                                        : 'Não definida'}</h4>
                                    {showPasswordUser === item.uid
                                        ? <AiOutlineEyeInvisible cursor='pointer' onClick={() => setShowPasswordUser('')} />
                                        : <AiOutlineEye cursor='pointer' onClick={() => setShowPasswordUser(item.uid)} />
                                    }
                                </div>
                                
                            </UserCardText>
                            <UserCardText>
                                <div>
                                    <span>Criado em:</span>
                                    <h4>{item.created}</h4>
                                </div>
                                <img 
                                    src={logIconGrey} 
                                    alt='icon icon' 
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setSelectedUser(item);
                                        setModalLogs(true)
                                    }}
                                />
                            </UserCardText>
                            
                        </UserCardContent>
                    </UserCard>
                ))}
                
            </UsersCardsContainer>
            
            <Modal
                isOpen={modalEditUser}
                onRequestClose={() => setModalEditUser(false)}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalFormUser 
                    data={selectedUser} 
                    title={selectedUser ? 'Editar usuário' : 'Novo usuário'}
                    onClose={() => setModalEditUser(false)}
                    loading={modalLoading}
                    onSave={async (newUser) => {
                        setModalLoading(true)
                        setTimeout(async () => {
                            if(selectedUser){
                                await trySaveUser(newUser);
                            }else{
                                await tryCreateUser(newUser);
                            }
                        }, 2000)
                    }}
                />
            </Modal>

            <Modal
                isOpen={modalConfirm}
                onRequestClose={() => {
                    setModalData('');
                    setModalConfirm(false)
                }}
                overlayClassName="modal-logs-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalConfirm 
                    data={modalData}
                    onCancel={() => {
                        setModalData('');
                        setModalConfirm(false)
                    }}
                    onConfirm={async () => {
                        await tryDeleteUser()
                    }}
                />
            </Modal>

            <Modal
                isOpen={modalLogs}
                onRequestClose={() => {
                    setModalLogs(false)
                }}
                overlayClassName="modal-logs-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalLogsUser userName={selectedUser.userName} userId={selectedUser.uid} onClose={() => {
                    setModalLogs(false)
                }}/>
            </Modal>
        </Container>
    )
}

export default ManageUsersPage;